import Header from 'pages/components/Header'
import React from 'react'
import styles from './VisionCI.module.scss'
import Footer from 'pages/components/Footer'
import ImgCi1 from 'static/images/img-ci-01@3x.png'
import ImgCi2 from 'static/images/img-ci-02@3x.png'
import BackgroundImg from 'static/images/sub-img-1.png'

const VisionCi = () => (
  <>
    <article className={styles['vision-ci']}>
      <Header
        title={'비젼 / CI'}
        discription={'플랜이슈 브랜드 가치와 이미지 제시'}
        backgroundImg={BackgroundImg}
      />
      <section className={styles['contents']}>
        <section className={styles['content']}>
          {/*           
          <h1>
            플랜이슈의 관광마케팅 컨설팅을 통하여
            <br />
            의뢰인이 원하는 콘텐츠를 개발하여 드립니다.
          </h1>
          <h2>
            의뢰인의 이야기를 현실화시켜 드립니다.
            <br />
            현실화된 이야기를 이슈화시켜 드립니다.
            <br />
            그리고 사업 차별화를 구현시켜드리겠습니다.
            <br />
            플랜이슈란 의뢰인이 원하는 이슈를 계획하여 드린다는 의미입니다.
          </h2>
          <div className={styles['line']}></div>
           */}
          <section className={styles['ci']}>
            <img src={ImgCi1} />
          </section>
          <section className={styles['ci']}>
            <img src={ImgCi2} />
          </section>
        </section>
      </section>
    </article>
    <Footer />
  </>
)

export default VisionCi
