import React from 'react'
import BusinessArea from './BusinessArea'
import Intro from './Intro'
import KeyMembers from './KeyMembers'
import OurNumbers from './OurNumbers'
import SNS from './SNS'
import Vision from './Vision'
import WhyPlanissue from './WhyPlanissue'
import './index.scss'
import Promotion from './Promotion'
import Footer from '../components/Footer'
import HeaderMenu from 'pages/components/HeaderMenu'
import HeaderMenuMobile from 'pages/components/HeaderMenuMobile'

const index = () => {
  return (
    <article className="main">
      <div className="header-menus">
      {window.innerWidth > 899 ? <HeaderMenu /> : <HeaderMenuMobile />}
      </div>
      <Intro />
      <Vision />
      <WhyPlanissue />
      <BusinessArea />
      <OurNumbers />
      <KeyMembers />
      {/* <SNS /> */}
      <Promotion />
      <Footer />
    </article>
  )
}

export default index
