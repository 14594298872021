import React, { useEffect, useState } from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './Insight.module.scss'
import BackgroundImg from 'static/images/sub-img-4@3x.png'
import InsightSampleImg from 'static/images/insight-sample.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import FullscreenSpinner from 'components/FullscreenSpinner'
import InfiniteScroll from 'react-infinite-scroll-component'
import { BeatLoader } from 'react-spinners'

const Insight = () => {
  const baseUrl = '/api/insight'
  const [url, setUrl] = useState(baseUrl)
  const [loading, setLoading] = useState(false)
  const [searchOption, setSearchOption] = useState('title')
  const [searchValue, setSearchValue] = useState('')
  const [dataSource, setDataSource] = useState([])
  const [startIdx, setStartIdx] = useState(0)
  const rows = 6
  const navigate = useNavigate()
  const [ids, setIds] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchData()
    fetchIds()
  }, [])

  const fetchData = () => {
    setLoading(true)
    axios
      .get(url, {
        params: {
          start: startIdx,
          rows,
        },
      })
      .then(({ data }) => {
        const responseData = data.data
        responseData.forEach((d, i, all) => {
          d.no = all.length - i
          d.create_time = d.create_time
            .replace('T', ' ')
            .substr(0, 10)
            .replace(/-/g, '.')
          return d
        })
        setDataSource([...dataSource, ...data.data])
        setStartIdx(startIdx + rows)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchIds = () => {
    if (ids.length < 1) {
      axios
        .get(`${url}/ids`)
        .then(({ data }) => {
          setIds(data ?? [])
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const refresh = () => {
    setDataSource([])
    setStartIdx(0)
  }

  const Card = ({ imgSrc, title, sub_title, content }) => (
    <section className={styles['card']}>
      <img src={imgSrc} />
      <div className={styles['card-container']}>
        <h1 className={styles['title']}>{title}</h1>
        <div className={styles['card-content']}>{sub_title}</div>
      </div>
    </section>
  )
  console.log(ids.length)
  return (
    <article className={styles['insight']}>
      <Header
        title="인사이트"
        discription="관광 트렌드와 현상에 대한 새로운 통찰력 제공"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']}>
        <section className={styles['container']}>
          {/* {loading || !dataSource ? (
            <FullscreenSpinner />
          ) : ( */}
            <InfiniteScroll
              dataLength={dataSource.length}
              next={fetchData}
              hasMore={dataSource.length < ids.length}
              loader={
                <div style={{ textAlign: 'center' }}>
                  <BeatLoader color={'#ed1c24'} />
                </div>
              }
              refreshFunction={refresh}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
            >
              {dataSource.map((d, i) => (
                <Link
                  to={`/story/insight/${d.id}`}
                  key={d.title + i}
                  state={{ paramData: d, ids }}
                >
                  <Card
                    key={d.id}
                    title={d.title}
                    sub_title={d.sub_title}
                    imgSrc={d.image}
                  />
                </Link>
              ))}
            </InfiniteScroll>
          {/* )} */}
        </section>
      </section>
      <Footer />
    </article>
  )
}

export default Insight
