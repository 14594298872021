import React from 'react'
import styles from './Footer.module.scss'
import IconInstagram from 'static/images/icon-instagram.svg'
import IconYoutube from 'static/images/icon-youtube.svg'
import IconFacebook from 'static/images/icon-facebook.svg'
import Logo from 'static/images/logo-color@3x.png'

const Icon = ({ src, title, openUrl }) => (
  <img
    className={styles['icon']}
    src={src}
    title={title}
    onClick={() => window.open(openUrl)}
  />
)

const Footer = () => (
  <section className={styles['footer']}>
    <article className={styles['content']}>
      <section className={styles['top']}>
        <div className={styles['logo']}>
          <img src={Logo} alt="plan!ssue" />
        </div>
        <div className={styles['sns']}>
          <Icon
            src={IconInstagram}
            title="Instagram"
            openUrl="https://www.instagram.com/planissue_official/?hl=ko"
          />
          {/* <Icon src={IconYoutube} title="Youtube" /> */}
          <Icon
            src={IconFacebook}
            title="Facebook"
            openUrl="https://www.facebook.com/%ED%94%8C%EB%9E%9C%EC%9D%B4%EC%8A%88-Plan-Issue-113707377096144/"
          />
        </div>
      </section>
      <section className={styles['address']}>
        서울시 마포구 동교로 23길 32-18 2층{' '}
        <span className={styles['company-name']}>㈜플랜이슈</span> 대표 : 김진성
        <br />
        사업자번호 104-86-43853
      </section>
      <section className={styles['company-official']}>
        © 2022 PLANISSUE.
      </section>
    </article>
  </section>
)

export default Footer
