import React, { useEffect, useState } from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './Notification.module.scss'
import './Notification.table.scss'
import BackgroundImg from 'static/images/sub-img-4@3x.png'
import { Button, Input, Select, Table } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { addQueryParameter } from 'utils'
import axios from 'axios'

const { Option } = Select

export const boardColumns = () => {
  const COLUMNS = [
    { title: 'no', key: 'no', align: 'center' },
    { title: '제목', key: 'title', align: 'left' },
    // { title: '작성자', key: 'register', align: 'center' },
    {
      title: '등록일',
      key: 'create_time',
      align: 'center',
      sorter: (a, b) =>
        a.create_time.replace(/[.]/g, '') - b.create_time.replace(/[.]/g, ''),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '조회수',
      key: 'hit',
      align: 'center',
      sorter: (a, b) => a.hit - b.hit,
      sortDirections: ['descend', 'ascend'],
    },
  ]
  const Column = (title, key, align = 'left', sorter, sortDirections, i) => ({
    title,
    dataIndex: key,
    key,
    align,
    sorter,
    sortDirections,
  })

  const columns = COLUMNS.map((c, i) =>
    Column(c.title, c.key, c.align, c.sorter, c.sortDirections, i)
  )
  return columns
}

const Notification = () => {
  const [category, setCategory] = useState()
  const [dataSource, setDataSource] = useState([])
  const baseUrl = '/api/board'
  const [url, setUrl] = useState(baseUrl)
  const [loading, setLoading] = useState(false)
  const [searchOption, setSearchOption] = useState('title')
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    axios
      .get(url)
      .then(({ data }) => {
        const responseData = data.data
        responseData.map((d, i, all) => {
          d.no = all.length - i
          d.create_time = d.create_time
            .replace('T', ' ')
            .substr(0, 10)
            .replace(/-/g, '.')
          return d
        })
        setDataSource(data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [url])

  const submit = () => {
    let urlWithQuery = addQueryParameter(baseUrl, 'searchOption', searchOption)
    urlWithQuery = addQueryParameter(urlWithQuery, 'searchValue', searchValue)
    setUrl(urlWithQuery)
  }
  const setPage = (page) => {
    location.hash = page
  }
  return (
    <article className={styles['notification']}>
      <Header
        title="공지사항"
        discription="플랜이슈 이벤트 및 공지내용 전달"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']}>
        <section className={styles['container']}>
          <section className={styles['search']}>
            {/* <select defaultValue="title" className={styles['search-select']}>
              <option value="title">제목</option>
              <option value="content">내용</option>
            </select>
            <input
              type="text"
              placeholder="검색어 입력"
              className={styles['search-input']}
            ></input>
            <button className={styles['search-button']}>검색</button>
             */}

            <Select
              defaultValue="title"
              className={styles['search-select']}
              size="large"
              onChange={(value) => {
                setSearchOption(value)
              }}
            >
              <Option value="title">제목</Option>
              <Option value="content">내용</Option>
            </Select>
            <Input
              placeholder="검색어 입력"
              className={styles['search-input']}
              size="large"
              allowClear
              onChange={(e) => setSearchValue(e.target.value)}
              onPressEnter={submit}
              value={searchValue}
              // autoFocus={true}
            />
            <Button
              className={styles['search-button']}
              size="large"
              onClick={submit}
            >
              검색
            </Button>
          </section>
          <section className={styles['table']}>
            <Table
              dataSource={dataSource}
              columns={boardColumns()}
              pagination={{
                position: ['bottomCenter'],
                onChange: setPage,
                defaultCurrent: location.hash
                  ? Number.parseInt(location.hash.split('#')[1])
                  : 1,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate(`/story/notification/${record.id}?no=${record.no}`)
                  },
                }
              }}
            />
          </section>
        </section>
      </section>
      <Footer />
    </article>
  )
}

export default Notification
