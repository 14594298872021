import React from 'react'
import './App.scss'
import './static/fonts/font.scss'
import Main from './pages/main'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Introduction from 'pages/about/Introduction'
import VisionCi from 'pages/about/VisionCI'
import TalentWelfare from 'pages/about/TalentWelfare'
import Contact from 'pages/about/Contact'
import RepresentativeGreeting from 'pages/consultant/RepresentativeGreeting'
import Members from 'pages/consultant/IssueMakers'
import Interviews from 'pages/consultant/Interviews'
import Portfolio from 'pages/portfolio/Portfolio'
import PortfolioDetail from 'pages/portfolio/PortfolioDetail'
import Notification from 'pages/story/Notification'
import NotificationDetail from 'pages/story/NotificationDetail'
import Inquiry from 'pages/story/Inquiry'
import Insight from 'pages/story/Insight'
import NewsLetter from 'pages/story/NewsLetter'
import Index from 'pages/admin'

import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/bundle'
import Board from 'pages/admin/Board'
import InquiryDetail from 'pages/story/InquiryDetail'
import AdminPortfolio from 'pages/admin/Portfolio'
import InterviewDetail from 'pages/consultant/InterviewDetail'
import InsightDetail from 'pages/story/InsightDetail'
import { Login } from 'pages/admin/Login'
import BoardWrite from 'pages/admin/Board\bWrite'
import AdminInquiry from 'pages/admin/AdminInquiry'
import AdminInquiryWrite from 'pages/admin/AdminInquiryWrite'
import { Interview } from 'pages/admin/Interview'
import { InterviewWrite } from 'pages/admin/InterviewWrite'
import AdminInsight from 'pages/admin/Insight'
import AdminInsightWrite from 'pages/admin/InsightWrite'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          {/* About */}
          <Route path="/about" element={<Introduction />} />
          <Route path="/about/introduction" element={<Introduction />} />
          <Route path="/about/vision-ci" element={<VisionCi />} />
          <Route path="/about/talent-welfare" element={<TalentWelfare />} />
          <Route path="/about/contact" element={<Contact />} />
          {/* Consultant */}
          <Route path="/consultant" element={<RepresentativeGreeting />} />
          <Route
            path="/consultant/representative-greeting"
            element={<RepresentativeGreeting />}
          />
          <Route path="/consultant/members" element={<Members />} />
          <Route path="/consultant/interviews" element={<Interviews />} />
          <Route
            path="/consultant/interview/:id"
            element={<InterviewDetail />}
          />
          {/* Portfolio */}
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:targetMenu" element={<Portfolio />} />
          <Route path="/portfolio/detail/:id" element={<PortfolioDetail />} />
          {/* Notification */}
          <Route path="/story" element={<Notification />} />
          <Route path="/story/notification" element={<Notification />} />
          <Route
            path="/story/notification/:id"
            element={<NotificationDetail />}
          />
          <Route path="/story/inquiry" element={<Inquiry />} />
          <Route path="/story/inquiry/:id" element={<InquiryDetail />} />
          <Route path="/story/insight" element={<Insight />} />
          <Route path="/story/insight/:id" element={<InsightDetail />} />
          <Route path="/story/newsletter" element={<NewsLetter />} />
          {/* Admin */}
          <Route path="/admin" element={<Index />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/interview" element={<Interview />} />
          <Route path="/admin/interview/write" element={<InterviewWrite />} />
          <Route path="/admin/board" element={<Board />} />
          <Route path="/admin/board/write" element={<BoardWrite />} />
          <Route path="/admin/portfolio" element={<AdminPortfolio />} />
          <Route path="/admin/portfolio/write" element={<BoardWrite />} />
          <Route path="/admin/inquiry" element={<AdminInquiry />} />
          <Route path="/admin/inquiry/write" element={<AdminInquiryWrite />} />
          <Route path="/admin/insight" element={<AdminInsight />} />
          <Route path="/admin/insight/write" element={<AdminInsightWrite />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
