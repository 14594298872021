import React, { useEffect, useState } from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './Inquiry.module.scss'
import BackgroundImg from 'static/images/sub-img-4@3x.png'
import InquirySampleImg from 'static/images/inquiry-sample.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import FullscreenSpinner from 'components/FullscreenSpinner'
import decode from 'unescape'
import LogoImg from 'static/images/members/Member_logo.png'
import EyeImg from 'static/images/eye.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import { BeatLoader } from 'react-spinners'

const Inquiry = () => {
  const baseUrl = '/api/inquiry'
  const [url, setUrl] = useState(baseUrl)
  const [loading, setLoading] = useState(false)
  const [searchOption, setSearchOption] = useState('title')
  const [searchValue, setSearchValue] = useState('')
  const [dataSource, setDataSource] = useState([])
  const [startIdx, setStartIdx] = useState(0)
  const [ids, setIds] = useState([])
  const rows = 6
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
    fetchIds()
  }, [url])

  const fetchData = () => {
    setLoading(true)
    axios
      .get(url, {
        params: {
          start: startIdx,
          rows,
        },
      })
      .then(({ data }) => {
        const responseData = data.data
        responseData.forEach((d, i, all) => {
          d.no = all.length - i
          d.create_time = d.create_time
            .replace('T', ' ')
            .substr(0, 10)
            .replace(/-/g, '.')
          return d
        })
        setDataSource([...dataSource, ...data.data])
        setStartIdx(startIdx + rows)
      })
      .catch((e) => {
        console.log(e)
        // debugger
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchIds = () => {
    if (ids.length < 1) {
      axios
        .get(`${url}/ids`)
        .then(({ data }) => {
          setIds(data ?? [])
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const refresh = () => {
    setDataSource([])
    setStartIdx(0)
  }

  const Card = ({
    title,
    content,
    date,
    imgSrc = { logo: LogoImg },
    hit = 0,
  }) => (
    <section className={styles['card']}>
      <div className={styles['image']}>
        <img
          src={imgSrc || LogoImg}
          style={{ height: '227px', width: '100%' }}
        ></img>
      </div>
      <div className={styles['card-container']}>
        <h1
          className={styles['title']}
          dangerouslySetInnerHTML={{ __html: decode(title) }}
        />
        <div
          className={styles['card-content']}
          dangerouslySetInnerHTML={{ __html: decode(content) }}
        />
        <div className={styles['date']}>
          {date}
          <span className={styles['hit']}>
            <img src={EyeImg} style={{ height: '25px' }} /> {hit}
          </span>
        </div>
      </div>
    </section>
  )

  return (
    <article className={styles['inquiry']}>
      <Header
        title="탐구생활"
        discription="이슈메이커들의 소소한 일상(회사) 이야기"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']}>
        <section className={styles['container']}>
          {/* {loading || !dataSource ? (
            <FullscreenSpinner />
          ) : ( */}
            <>
              {!loading && dataSource.length < 1 ? (
                <>데이터가 없습니다</>
              ) : (
                <InfiniteScroll
                  dataLength={dataSource.length} //This is important field to render the next data
                  next={fetchData}
                  hasMore={true}
                  loader={
                    <div style={{ textAlign: 'center' }}>
                      <BeatLoader color={'#ed1c24'} />
                    </div>
                  }
                  refreshFunction={refresh}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                >
                  {dataSource.map((d, i) => (
                    <Link
                      to={`/story/inquiry/${d.id}`}
                      key={d.id}
                      state={{ paramData: d }}
                    >
                      <Card
                        title={d.title}
                        content={d.content}
                        date={d.create_time.replace('T', ' ').substr(0, 10)}
                        imgSrc={d.image}
                        hit={d.hit}
                      />
                    </Link>
                  ))}
                </InfiniteScroll>
              )}
            </>
          {/* )} */}
        </section>
      </section>
      <Footer />
    </article>
  )
}

export default Inquiry
