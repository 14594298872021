import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import React, { useState } from 'react'
import styles from './IssueMakers.module.scss'
// ㄱ
import 김미선_gray from 'static/images/members/김미선_gray.png'
import 김미선 from 'static/images/members/김미선.png'
import 김민정_gray from 'static/images/members/김민정_gray.png'
import 김민정 from 'static/images/members/김민정.png'
import 김수연_gray from 'static/images/members/김수연_gray.png'
import 김수연 from 'static/images/members/김수연.png'
import 김슬기_gray from 'static/images/members/김슬기_gray.png'
import 김슬기 from 'static/images/members/김슬기.png'
import 김진성_gray from 'static/images/members/김진성_gray.png'
import 김진성 from 'static/images/members/김진성.png'
import 김현지_gray from 'static/images/members/김현지_gray.png'
import 김현지 from 'static/images/members/김현지.png'
import 김혜리_gray from 'static/images/members/김혜리_gray.jpg'
import 김혜리 from 'static/images/members/김혜리.jpg'

// ㄴ
import 남유진_gray from 'static/images/members/남유진_gray.jpg'
import 남유진 from 'static/images/members/남유진.jpg'

// ㅂ
import 박경표_gray from 'static/images/members/박경표_gray.png'
import 박경표 from 'static/images/members/박경표.png'
import 박서희_gray from 'static/images/members/박서희_gray.png'
import 박서희 from 'static/images/members/박서희.png'
import 박주란_gray from 'static/images/members/박주란_gray.png'
import 박주란 from 'static/images/members/박주란.png'
import 방희원_gray from 'static/images/members/방희원_gray.png'
import 방희원 from 'static/images/members/방희원.png'

// ㅇ
import 이고은_gray from 'static/images/members/이고은_gray.jpg'
import 이고은 from 'static/images/members/이고은.jpg'
import 이소연_gray from 'static/images/members/이소연_gray.png'
import 이소연 from 'static/images/members/이소연.png'
import 이소정_gray from 'static/images/members/이소정_gray.png'
import 이소정 from 'static/images/members/이소정.png'
import 이슬아_gray from 'static/images/members/이슬아_gray.png'
import 이슬아 from 'static/images/members/이슬아.png'
import 이욱_gray from 'static/images/members/이욱_gray.png'
import 이욱 from 'static/images/members/이욱.png'
import 이형백_gray from 'static/images/members/이형백_gray.jpg'
import 이형백 from 'static/images/members/이형백.jpg'

// 기타
import 송민아_gray from 'static/images/members/송민아_gray.jpg'
import 송민아 from 'static/images/members/송민아.jpg'
import 정경수_gray from 'static/images/members/정경수_gray.png'
import 정경수 from 'static/images/members/정경수.png'
import 조수민_gray from 'static/images/members/조수민_gray.png'
import 조수민 from 'static/images/members/조수민.png'
import 최예진_gray from 'static/images/members/최예진_gray.png'
import 최예진 from 'static/images/members/최예진.png'
import 최윤석_gray from 'static/images/members/최윤석_gray.png'
import 최윤석 from 'static/images/members/최윤석.png'
import 홍현택_gray from 'static/images/members/홍현택_gray.jpg'
import 홍현택 from 'static/images/members/홍현택.jpg'

import logo from 'static/images/members/Member_logo.png'
import BackgroundImg from 'static/images/sub-img-2.png'
import Modal from 'react-modal'
import { NodeExpandOutlined } from '@ant-design/icons'

const Members = () => {
  const [visible, setVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})

  const showModal = ({ imgSrc, name, title, introduction, discription }) => {
    setSelectedUser({ imgSrc, name, title, introduction, discription })
    setVisible(true)
  }

  const Member = ({
    imgGreySrc,
    imgSrc,
    name,
    title,
    introduction,
    discription,
    clickHandler,
  }) => (
    <article className={styles['member']}>
      <img src={imgGreySrc} className={styles['img-grey']} />
      <img
        onClick={
          clickHandler
            ? clickHandler
            : () =>
                showModal({ imgSrc, name, title, introduction, discription })
        }
        src={imgSrc}
        className={styles['img-active']}
      />
      {name ? (
        <section className={styles['member-info']}>
          <span className={styles['name']}>{name}</span>
          <span className={styles['title']}>{title}</span>
        </section>
      ) : null}
    </article>
  )

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      border: 'none',
      borderRadius: 'none',
    },
  }

  return (
    <>
      <article className={styles['members']}>
        <Header
          title="이슈메이커"
          discription="<span>최적의 솔루션을 제공하는</span> <span>플랜이슈의 색다른 관광컨설턴트들</span>"
          backgroundImg={BackgroundImg}
        />
        <section className={styles['contents']}>
          <Member
            imgGreySrc={김진성_gray}
            imgSrc={김진성}
            title="대표"
            name="김진성"
            introduction={`CEO / Imagineer / Gregory Kim`}
            discription={`
              <b>학력</b> - 1999 경기대학교 대학원 관광개발학 석사 졸업<br/>
              1994 경기대학교 관광개발학과 학사 졸업<br/>
              <b>경력</b> - 2012~ 현 ㈜플랜이슈 대표<br/>
              2013~2015 세종특별자치시 관광시책자문단 위원<br/>
              2011 대전 국제 푸드와인 페스티벌 개발위원<br/>
              2010 서울시 관광자문위원(관광 스토리텔링 부문)<br/>
              2006~2012 ㈜디이파트너스 이사<br/>
              1999~2005 국토연구원 국토환경연구실 연구원<br/>
              <b>분야</b> - 멀티플레이어
            `}
          />
          <Member
            imgGreySrc={방희원_gray}
            imgSrc={방희원}
            title="과장"
            name="방희원"
            introduction="General Manager / Heewon Bang"
            discription={`
              <b>학력</b> - 2018 경기대학교 대학원 여가관광개발학 석사 졸업<br/>
              2016 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2019~ 현 ㈜플랜이슈 과장<br/>
              <b>분야</b> - 생활관광, 수변관광<br/>
            `}
          />
          {/* <Member
            imgGreySrc={정경수_gray}
            imgSrc={정경수}
            title="주임"
            name="정경수"
            introduction="Assit. Manager / Kelley Jung"
            discription={`
              <b>학력</b> - 2019 한양대학교 국제관광대학원 융합관광전공 석사 졸업<br/>
              2014 가톨릭관동대학교 호텔경영학 학사 졸업<br/>
              <b>경력</b> - 2020~ 현 ㈜플랜이슈 주임<br/>
              2018 SMTOWN TRAVEL 상용사업본부 대리<br/>
              2014~2017 ㈜하나투어 영업기획부 대리<br/>
              <b>분야</b> - 산업관광, 여행상품개발
            `}
          /> */}
          <Member
            imgGreySrc={이슬아_gray}
            imgSrc={이슬아}
            title="팀장"
            name="이슬아"
            introduction="Team Manager / Lee Seula"
            discription={`
              <b>학력</b> - 2020 서울시립대학교 도시과학대학원 문화예술관광학과 졸업(관광문화학 석사)<br/>
              2015 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2014~ 현 ㈜플랜이슈 팀장<br/>
              프랑스 Universitare Montpellier 2 교환학생<br/>
              <b>분야</b> - 반려동물, 생태관광
            `}
          />
          <Member
            imgGreySrc={김혜리_gray}
            imgSrc={김혜리}
            title="주임"
            name="김혜리"
            introduction="Assit. Manager / Hyeri Kim"
            discription={`
              <b>학력</b> - 2024 한양대학교 국제관광대학원 융합관광 석사 졸업<br/>
              2018 동서대학교 관광경영학 학사 졸업<br/>
              <b>경력</b> - 2023 ~ 현 ㈜플랜이슈 주임<br/>
              2021-2023 펀데이코리아네트웍스 대리<br/>
              2018-2020 코이카 해외봉사단(파라과이-관광)<br/>
              <b>분야</b> - 융합관광, 관광진흥
            `}
          />
          {/* <Member imgGreySrc={logo} imgSrc={logo} clickHandler={() => {}} /> */}
          <Member
            imgGreySrc={김현지_gray}
            imgSrc={김현지}
            title="과장"
            name="김현지"
            introduction="General Manager / Kim Hyunji"
            discription={`
              <b>학력</b> - 2013 서울시립대학교 조경학과 석사 졸업<br/>
              2011 전남대학교 조경학 학사 졸업<br/>
              <b>경력</b> - 2020~ 현 ㈜플랜이슈 과장<br/>
              2018~2020 ㈜동해종합기술공사 조경레저부 과장<br/>
              2014~2017 ㈜천마이앤씨 대리<br/>
              <b>자격사항</b> - 조경기사1급<br/>
              <b>분야</b> - 조경기사, 경관사업
            `}
          />
          <Member
            imgGreySrc={이소연_gray}
            imgSrc={이소연}
            title="실장"
            name="이소연"
            introduction="Chief Manager / Lee, So-Yeoun"
            discription={`
              <b>학력</b> - 2000 경기대학교 대학원 관광개발학 석사 졸업<br/>
              <b>경력</b> - 2012~ 현 ㈜플랜이슈 실장<br/>
              2009~2011 ㈜다음레저 과장<br/>
              1996~1998 한일합섬 레저기획부<br/>
              1998 충청북도 단양군 관광자문 위원<br/>
              <b>분야</b> - 인사 및 재무관리
            `}
          />
          <Member
            imgGreySrc={이욱_gray}
            imgSrc={이욱}
            title="팀장"
            name="이욱"
            introduction="Team Manager / Lee Uk"
            discription={`
              <b>학력</b> - 2018 경기대학교 대학원 여가관광개발학 석사 졸업<br/>
              2016 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2017~ 현 ㈜플랜이슈 팀장<br/>
              2016 Vietnam Yen Tu Mountain Project Intership<br/>
              <b>분야</b> - 중앙정부공모사업, 운영관리
            `}
          />
          <Member
            imgGreySrc={김민정_gray}
            imgSrc={김민정}
            title="실장"
            name="김민정"
            introduction="Chief Manager / Kim Min Jung"
            discription={`
              <b>학력</b> - 2002 경희대학교 대학원 조경학전공 석사 수료<br/>
              2000 경희대학교 조경학 학사 졸업<br/>
              <b>경력</b> - 2020~ 현 ㈜플랜이슈 실장<br/>
              2019~2020 ㈜한종 레저조경부 부장<br/>
              2016~2019 ㈜예당엔지니어링 조경부 부장<br/>
              2015~2016 강산어소시에이트 조경부 차장<br/>
              2010~2012 ㈜동해종합기술공사 차장<br/>
              <b>자격사항</b> - 조경기사1급<br/>
              <b>분야</b> - 조경기사, 관광법규
            `}
          />
          {/* <Member
            imgGreySrc={박경표_gray}
            imgSrc={박경표}
            title="대리"
            name="박경표"
            introduction="Assit. Manager / Gyeongpyo Park"
            discription={`
              <b>학력</b> - 2022 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2022~ 현 ㈜플랜이슈 대리<br/>
              2021~2022 이랜드파크 켄싱턴호텔앤리조트 인턴<br/>
              2020~2021 KOICA YP 지구촌나눔운동 인턴<br/>
              2019 삼성물산 에버랜드 캐스트<br/>
              <b>분야</b> - 콘텐츠개발, 관광서비스 
            `}
          /> */}
          {/* <Member
            imgGreySrc={김미선_gray}
            imgSrc={김미선}
            title="과장"
            name="김미선"
            introduction="General Manager / Kim, Miseon"
            discription={`
              <b>학력</b> - 2020 한양대학교 대학원 관광학과 석사 졸업<br/>
              2011 한밭대학교 중국어과 학사 졸업<br/>
              <b>경력</b> - 2019~ 현 ㈜플랜이슈 과장<br/>
              2017~2018 한양대학교 관광연구소 연구원<br/>
              2013~2015 서울시관광협회 관광안내통역사(중국어)<br/>
              <b>분야</b> - 한류관광, 문화콘텐츠 개발
            `}
          />
           */}
          <Member
            imgGreySrc={이고은_gray}
            imgSrc={이고은}
            title="사원"
            name="이고은"
            introduction="Staff / Goeun Lee"
            discription={`
              <b>학력</b> - 2024 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2023~ 현 ㈜플랜이슈 사원<br/>
              <b>분야</b> - 관광개발, 관광 빅데이터
            `}
          />
          {/* <Member imgGreySrc={logo} imgSrc={logo} clickHandler={() => {}} /> */}
          <Member
            imgGreySrc={조수민_gray}
            imgSrc={조수민}
            title="대리"
            name="조수민"
            introduction="Assit. Manager / Sumin Jo"
            discription={`
              <b>학력</b> - 2022 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2021~ 현 ㈜플랜이슈 대리<br/>
              2020 KBO 공식기록원 인턴<br/>
              <b>분야</b> - 관광개발, 스포츠관광
            `}
          />
          {/* <Member
            imgGreySrc={최예진_gray}
            imgSrc={최예진}
            title="과장"
            name="최예진"
            introduction="Assit. Manager / Choi, Ye jin"
            discription={`
              <b>학력</b> - 2020 경기대학교 일반대학원 여가관광개발학 석사 졸업<br/>
              2018 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2019~ 현 ㈜플랜이슈 과장<br/>
              <b>분야</b> - 빅데이터, 관광클러스터
            `}
          /> */}
          <Member
            imgGreySrc={송민아_gray}
            imgSrc={송민아}
            title="주임"
            name="송민아"
            introduction="Assit. Manager / Mina Song"
            discription={`
              <b>학력</b> - 2024 신구대학교 환경조경학 학사 졸업<br/>
              <b>경력</b> - 2023~ 현 ㈜플랜이슈 주임<br/>
              <b>분야</b> - 엔지니어링, 마스터플랜
            `}
          />
          {/* <Member imgGreySrc={logo} imgSrc={logo} clickHandler={() => {}} /> */}
          <Member
            imgGreySrc={김슬기_gray}
            imgSrc={김슬기}
            title="과장"
            name="김슬기"
            introduction="General Manager / Kim, Seul-Ki"
            discription={`
              <b>학력</b> - 2019 단국대학교 커뮤니케이션학부 광고홍보 학사 졸업<br/>
              <b>경력</b> - 2020~ 현 ㈜플랜이슈 과장<br/>
              2019 ㈜커뮤니크 PR&Digital 사원<br/>
              2016~2018 ㈜플랜이슈 사원<br/>
              <b>분야</b> - 광고홍보, 스토리텔링
            `}
          />
          <Member
            imgGreySrc={최윤석_gray}
            imgSrc={최윤석}
            title="팀장"
            name="최윤석"
            introduction="Team Manager / Choi Yoonseok"
            discription={`
              <b>학력</b> - Samsung Art & Design Institute Communication Design 중퇴<br/>
              <b>경력</b> - 2019~ 현 ㈜플랜이슈 팀장<br/>
              2017~2018 CJ E&M tvN 로케이션 매니저<br/>
              2010~2017 피스마켓 대표<br/>
              2009~2010 (사)국제시각교류협회 캔파운데이션 객원디자이너<br/>
              2008~2009 토탈미술관 객원디자이너<br/>
              2004~2005 BlindSound Designers Party 객원디자이너<br/>
              2003~2010 Fletcher Syndrom 실장<br/>
              2001~2003 Design Crue 팀장<br/>
              <b>분야</b> - 시각 디자인, 브랜딩            
            `}
          />
          {/* <Member
            imgGreySrc={박주란_gray}
            imgSrc={박주란}
            title="과장"
            name="박주란"
            introduction="Assit. Manager /  Park Ju Ran"
            discription={`
              <b>학력</b> - 2018 대구대학교 일반대학원 관광경영학 석사 졸업<br/>
              2016 대구대학교 호텔관광과 학사 졸업 <br/>
              <b>경력</b> - 2021~ 현 ㈜플랜이슈 과장<br/>
              2018~2021 ㈜에코다임 대리<br/>
              2017 농촌진흥청 국립농업과학원 연구원<br/>
              2016 일본 니가타현 뉴오다니 호텔 인턴<br/>
              <b>분야</b> - 농촌관광, 지역아카이빙
            `}
          /> */}
          {/* <Member
            imgGreySrc={김수연_gray}
            imgSrc={김수연}
            title="주임"
            name="김수연"
            introduction="Assit. Manager / Sooyeon Kim"
            discription={`
              <b>학력</b> - 2019 한양대학교 대학원 관광학과 석사 졸업<br/>
              <b>경력</b> - 2021~ 현 ㈜플랜이슈 주임<br/>
              <b>분야</b> - 도시재생, 빅데이터
            `}
          /> */}
          <Member
            imgGreySrc={박서희_gray}
            imgSrc={박서희}
            title="이사"
            name="박서희"
            introduction="Executive Director / Park, Seo-Hee"
            discription={`
              <b>학력</b> - 2003 경기대학교 관광개발학 학사 졸업<br/>
              <b>경력</b> - 2017~ 현 ㈜플랜이슈 이사<br/>
              2013~2014 ㈜흥일 사업기획팀 대리<br/>
              2012~2013 (유)청심그룹 사업기획팀 대리<br/>
              2007~2012 ㈜디이파트너스 컨설팅팀 팀장<br/>
              <b>분야</b> - 사업 타당성, 단지계획
            `}
          />
          <Member
            imgGreySrc={이소정_gray}
            imgSrc={이소정}
            title="대리"
            name="이소정"
            introduction="Assit. Manager / Sojeong Lee"
            discription={`
              <b>학력</b> - 2022 한양대학교 대학원 관광학 석사 졸업<br/>
              <b>경력</b> - 2022~ 현 ㈜플랜이슈 대리<br/>
              2020~2022 한양대학교 관광연구소 연구원<br/>
              <b>분야</b> - 관광레저, 축제이벤트
            `}
          />
          <Member
            imgGreySrc={홍현택_gray}
            imgSrc={홍현택}
            title="과장"
            name="홍현택"
            introduction="General Manager / Hong, Hyun Taek"
            discription={`
              <b>학력</b> - 2022 경기대학교 일반대학원 여가관광개발학 석사 졸업<br/>
              <b>경력</b> - 2022~ 현 ㈜플랜이슈 과장<br/>
              2020~2022 ㈜큐브 커뮤니케이션 연구원<br/>
              <b>분야</b> - 지역사회기반관광, 해양관광
            `}
          />
          <Member
            imgGreySrc={이형백_gray}
            imgSrc={이형백}
            title="주임"
            name="이형백"
            introduction="Assit. Manager / Hyeong Baek Lee"
            discription={`
              <b>학력</b> - 2023 경기대학교 일반대학원 여가관광개발학 석사 졸업<br/>
              <b>경력</b> - 2023~ 현 ㈜플랜이슈 주임<br/>
              <b>분야</b> - 관광개발, 체험관광
            `}
          />
          <Member
            imgGreySrc={남유진_gray}
            imgSrc={남유진}
            title="사원"
            name="남유진"
            introduction="Staff / Yujin Nam"
            discription={`
              <b>학력</b> - 2025 경기대학교 관광개발학 학사 졸업 예정<br/>
              <b>경력</b> - 2024~ 현 ㈜플랜이슈 사원<br/>
              <b>분야</b> - 지역관광개발, 빅데이터분석
            `}
          />
        </section>
        <Modal isOpen={visible} style={customStyles}>
          <article className={styles['modal']}>
            <section className={styles['modal-content']}>
              <div className={styles['modal-img-container']}>
                <img
                  src={selectedUser.imgSrc}
                  className={styles['modal-img']}
                />
              </div>
              <section className={styles['modal-content-right']}>
                <div
                  className={styles['close-button']}
                  onClick={() => setVisible(false)}
                ></div>
                <section className={styles['modal-right-content']}>
                  <div>
                    <div className={styles['member-name-title']}>
                      <span className={styles['member-name']}>
                        {selectedUser.name}
                      </span>
                      <span className={styles['member-title']}>
                        {selectedUser.title}
                      </span>
                    </div>
                    <section className={styles['introduction']}>
                      {selectedUser.introduction}
                    </section>
                    <section
                      className={styles['discription']}
                      dangerouslySetInnerHTML={{
                        __html: selectedUser.discription,
                      }}
                    ></section>
                  </div>
                </section>
              </section>
            </section>
          </article>
        </Modal>
      </article>
      <Footer />
    </>
  )
}

export default Members
