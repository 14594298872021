import React, { useEffect, useRef, useState } from 'react'
import styles from './Vision.module.scss'
import Lottie from 'lottie-web'
import fireworks from 'static/lotties/fireworks.json'
import ReactVisibilitySensor from 'react-visibility-sensor'

const Vision = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const background = useRef(null)
  useEffect(() => {
    Lottie.loadAnimation({
      container: background.current,
      animationData: fireworks,
    })
  }, [])
  // const isVisible = true
  return (
    <section className={`container ${styles['vision']}`}>
      <div className={styles['background']} ref={background}></div>
      <ReactVisibilitySensor
        onChange={(isVisible) => isVisible && setIsLoaded(true)}
      >
        {({ isVisible }) => (
          <article
            className={styles['content']}
            // style={{ backgroundColor: isVisible ? 'red' : 'blue' }}
          >
            <section className={styles['top']}>
              <div
                className={`${styles['top-content']} ${
                  isVisible || isLoaded ? styles['rise-up'] : ''
                }`}
              >
                <div className={styles.title1}>Vision of 2024</div>
                <div className={styles.title2}>Go for it</div>
                <div className={styles.title3}>
                  <span>如鳥數飛</span>
                </div>
              </div>
            </section>
            <section
              className={`${styles['middle']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <p>
                플랜이슈에서는 바로 실행에 옮길 수 없었던 기존 관광컨설팅의
                한계를 넘어
              </p>
              <p>
                빠르고 사회적으로 이슈화 될 수 있는 계획을 마련하는데 도움이
                되어드리겠습니다.
              </p>
            </section>
            <section
              className={`${styles['bottom']} ${
                isVisible || isLoaded ? styles['typing'] : ''
              }`}
            >
              무슨 좋은 계획 있으십니까?
            </section>
            <section className={styles['bottom-button']}>
              <a href="/about/contact">
                <button
                  className={`${styles['apply-button']} ${
                    isVisible || isLoaded ? styles['fadein'] : ''
                  }`}
                >
                  <span>사업 제안하기 &nbsp;&gt;</span>
                </button>
              </a>
            </section>
          </article>
        )}
      </ReactVisibilitySensor>
    </section>
  )
}

export default Vision
