import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './HeaderMenuMobile.module.scss'
import LogoWhite from 'static/images/logo-wh@3x.png'
import MobileMenuWhiteIcon from 'static/images/m-menu-white.svg'

const HeaderMenuMobile = () => {
  const menuRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [isAboutOpen, setIsAboutOpen] = useState(false)
  const [isConsultantOpen, setIsConsultantOpen] = useState(false)
  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false)
  const [isStoryOpen, setIsStoryOpen] = useState(false)
  const mouseHandler = (isOpen) => {
    if (isOpen) {
      menuRef.current.classList.add(styles['open'])
    } else {
      menuRef.current.classList.remove(styles['open'])
    }
    setIsOpen(isOpen)
  }

  return (
    <>
      <header className={styles['header']}>
        <article className={styles['header-contents']}>
          <section className={styles['logo']}>
            <Link to="/">
              <img
                className={styles['logo-image']}
                src={LogoWhite}
                alt="PLAN!SSUE"
              />
            </Link>
            <img
              src={MobileMenuWhiteIcon}
              className={styles['mobile-menu']}
              onClick={mouseHandler}
            />
          </section>
          <section className={styles['header-menus']} ref={menuRef}>
            <div className={`${styles['header-menu']}`}>
              <div
                className={styles['main-menu']}
                onClick={() => setIsAboutOpen(!isAboutOpen)}
              >
                ABOUT
              </div>
              <div
                className={`${styles['sub-menus']} ${
                  isAboutOpen ? styles['open'] : ''
                }`}
              >
                <div className={styles['sub-menu']}>
                  <Link to="/about/introduction">플랜이슈 소개</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/about/vision-ci">비젼/CI</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/about/talent-welfare">인재상 및 복리후생</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/about/contact">CONTACT</Link>
                </div>
              </div>
            </div>
            <div className={`${styles['header-menu']}`}>
              <div
                className={styles['main-menu']}
                onClick={() => setIsConsultantOpen(!isConsultantOpen)}
              >
                CONSULTANT
              </div>
              <div
                className={`${styles['sub-menus']} ${
                  isConsultantOpen ? styles['open'] : ''
                }`}
              >
                <div className={styles['sub-menu']}>
                  <Link to="/consultant/representative-greeting">대표이사</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/consultant/members">이슈메이커</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/consultant/interviews">인터뷰</Link>
                </div>
              </div>
            </div>
            <div className={`${styles['header-menu']}`}>
              <div
                className={styles['main-menu']}
                onClick={() => setIsPortfolioOpen(!isPortfolioOpen)}
              >
                PORTFOLIO
              </div>
              <div
                className={`${styles['sub-menus']} ${
                  isPortfolioOpen ? styles['open'] : ''
                }`}
              >
                <div className={styles['sub-menu']}>
                  <Link to="/portfolio/strategic">전략적 관광컨설팅</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/portfolio/tourism-promotion">관광진흥컨설팅</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/portfolio/oper-mgm-commecial">
                    운영관리 및 사업화
                  </Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/portfolio/space-tourism-dev">
                    공간 및 관광개발산업
                  </Link>
                </div>
              </div>
            </div>
            <div className={`${styles['header-menu']}`}>
              <div
                className={styles['main-menu']}
                onClick={() => setIsStoryOpen(!isStoryOpen)}
              >
                STORY
              </div>
              <div
                className={`${styles['sub-menus']} ${
                  isStoryOpen ? styles['open'] : ''
                }`}
              >
                <div className={styles['sub-menu']}>
                  <Link to="/story/notification">공지사항</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/story/inquiry">탐구생활</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/story/insight">인사이트</Link>
                </div>
                <div className={styles['sub-menu']}>
                  <Link to="/story/newsletter">플랜사보</Link>
                </div>
              </div>
            </div>
            <div
              className={styles['close-button']}
              onClick={() => mouseHandler(false)}
            ></div>
          </section>
        </article>
      </header>
      {/* {isOpen ? (
        <div className={`${styles['safe-area']} safe-area`}></div>
      ) : null} */}
    </>
  )
}

export default HeaderMenuMobile
