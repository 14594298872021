import React, { useEffect, useState } from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './NotificationDetail.module.scss'
import './Notification.table.scss'
import BackgroundImg from 'static/images/sub-img-4@3x.png'
import { Button, Input, Select, Table } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import FullscreenSpinner from 'components/FullscreenSpinner'
import { boardColumns } from './Notification'
import decode from 'unescape'
import { Viewer } from '@toast-ui/react-editor'

const { Option } = Select

const NotificationDetail = () => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const no = params.get('no')
  const baseUrl = `/api/board/${id}`
  const [url, setUrl] = useState(baseUrl)
  const [data, setData] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)
    axios
      .get(url)
      .then(({ data }) => {
        setData(data)
        patchHit()
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })

  }, [url])

  const patchHit = () => {
    axios
      .patch(`${baseUrl}`, {
        hit: true,
      })
      .then((res) => {
        console.log('조회수 증가')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <article className={styles['notification-detail']}>
      <Header
        title="공지사항"
        discription="플랜이슈 이벤트 및 공지내용 전달"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']}>
        <section className={styles['container']}>
          {loading || !data ? (
            <FullscreenSpinner />
          ) : (
            <article>
              <section>
                <div className={styles.header}>
                  <div>{no}</div>
                  <div className={styles.title}>{data.title}</div>
                  <div>{data.register}</div>
                  <div>
                    {data.create_time
                      .replace('T', ' ')
                      .substr(0, 10)
                      .replace(/-/g, '.')}
                  </div>
                </div>
                <div
                  className={styles.detail}
                  dangerouslySetInnerHTML={{ __html: decode(data.content) }}
                >
                  {/* <Viewer initialValue={data.content} /> */}
                </div>
              </section>
              <section className={styles['bottom-button']}>
                <Button className={styles.button} onClick={() => navigate(-1)}>
                  목록
                </Button>
              </section>
            </article>
          )}
        </section>
      </section>
      <Footer />
    </article>
  )
}

export default NotificationDetail
