import FullscreenSpinner from 'components/FullscreenSpinner'
import useFetch from 'hooks/useFetch'
import { API_URL } from 'myconstant'
import AdminLayout from 'pages/components/admin/AdminLayout'
import React, { useCallback, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/react-editor'
import { Breadcrumb, Button, Input, Select, Table } from 'antd'
import { useEffect } from 'react'
import axios from 'axios'
import { addQueryParameter } from 'utils'
import date from 'date-and-time'
import styles from './Board.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'

const AdminInquiry = () => {
  const [dataSource, setDataSource] = useState([])
  // const [columns, setColumns] = useState([])
  const baseUrl = '/api/inquiry'
  const [url, setUrl] = useState(baseUrl)
  const [loading, setLoading] = useState(false)
  const [searchOption, setSearchOption] = useState('title')
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()

  const columns = [
    {
      title: 'no',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '등록일',
      dataIndex: 'create_time',
      key: 'create_time',
    },
  ]

  //title, register_id, notice, category, content, image, hit, create_time, update_time

  useEffect(() => {
    setLoading(true)
    axios
      .get(url)
      .then(({ data }) => {
        const responseData = data.data
        responseData.map((d, i, all) => {
          d.no = all.length - i + 1
          d.create_time = d.create_time.replace('T', ' ').substr(0, 19)
          return d
        })
        setDataSource(data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [url])

  const selectBefore = (
    <Select
      defaultValue={'title'}
      onChange={(value) => {
        setSearchOption(value)
      }}
    >
      <Select.Option key="title">제목</Select.Option>
      <Select.Option key="content">내용</Select.Option>
    </Select>
  )

  const search = () => {
    let urlWithQuery = addQueryParameter(baseUrl, 'searchOption', searchOption)
    urlWithQuery = addQueryParameter(urlWithQuery, 'searchValue', searchValue)
    setUrl(urlWithQuery)
  }

  const write = () => {
    navigate(`/admin/inquiry/write`)
  }

  const goToDetail = (record) => {
    const { id, title, content, image } = record
    navigate(`/admin/inquiry/write`, { state: { id, title, content, image } })
  }

  return (
    <AdminLayout>
      {loading ? (
        <FullscreenSpinner />
      ) : (
        <>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Story</Breadcrumb.Item>
            <Breadcrumb.Item>탐구생활</Breadcrumb.Item>
            <Breadcrumb.Item>목록</Breadcrumb.Item>
          </Breadcrumb>
          <div
            className={styles['button-area']}
            style={{ marginBottom: '20px' }}
          >
            <div>
              <Input
                allowClear
                style={{ width: '300px' }}
                addonBefore={selectBefore}
                onChange={(e) => setSearchValue(e.target.value)}
                onPressEnter={search}
                value={searchValue}
                autoFocus={true}
              />
              <Button type="primary" onClick={search}>
                검색
              </Button>
            </div>
            <Button type="primary" onClick={write}>
              글쓰기
            </Button>
          </div>
          <Table
            className={styles.table}
            dataSource={dataSource}
            columns={columns}
            pagination={{ position: ['bottomCenter'] }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  goToDetail(record)
                },
              }
            }}
          ></Table>
        </>
      )}
    </AdminLayout>
  )
}

export default AdminInquiry
