import React from 'react'
import styles from './Header.module.scss'
import HeaderMenu from './HeaderMenu'
import HeaderMenuMobile from './HeaderMenuMobile'
import decode from 'unescape'

const Header = ({ title, discription, backgroundImg }) => {
  return (
    <header
      className="header"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
      }}
    >
      {window.innerWidth > 899 ? <HeaderMenu /> : <HeaderMenuMobile />}

      <article className={styles['sub-header']}>
        <section>
          <section className={styles['title']}>{title}</section>
          <section className={styles['discription']} dangerouslySetInnerHTML={{ __html: decode(discription) }}></section>
        </section>
      </article>
    </header>
  )
}

export default Header
