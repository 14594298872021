import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import React, { useEffect, useRef } from 'react'
import styles from './InterviewDetail.module.scss'
import BackgroundImg from 'static/images/sub-img-2.png'
import 김진성 from 'static/images/members/김진성.png'
import { Link, useLocation } from 'react-router-dom'
import decode from 'unescape'

const InterviewDetail = () => {
  const location = useLocation()
  const { state = {} } = location
  const { imgSrc, interviewImg, title, subTitle, content } = state

  const targetEle = useRef()
  useEffect(() => {
    targetEle.current?.scrollIntoView()
  }, [targetEle?.current?.complete])

  return (
    <>
      <article className={styles['interview']}>
        <Header
          title="인터뷰"
          discription="이슈메이커들이 전하는 직무 이야기"
          backgroundImg={BackgroundImg}
        />
        <article className={styles['contents']}>
          <section className={styles['content']}>
            <section className={styles['header']}>
              <img
                src={interviewImg}
                onLoad={(e) => {
                  e.currentTarget.scrollIntoView()
                }}
              />
            </section>
            <article className={styles['interview-content']}>
              <article
                className={styles['answer']}
                dangerouslySetInnerHTML={{ __html: decode(content) }}
              ></article>
            </article>
          </section>
        </article>
        <div className={styles['right-buttons']}>
          <Link to={`/consultant/interviews`}>
            <div className={styles['footer-button']}>목록</div>
          </Link>
        </div>
      </article>
      <Footer />
    </>
  )
}
export default InterviewDetail
