import Header from 'pages/components/Header'
import React, { useEffect, useState } from 'react'
import styles from './Contact.module.scss'
import Footer from 'pages/components/Footer'
import IconArrowRed from 'static/images/arrow-red.svg'
import BackgroundImg from 'static/images/sub-img-1.png'
import { useRef } from 'react'
import axios from 'axios'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'

const Contact = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const formRef = useRef()
  const [agreed, setAgreed] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoaded) {
      // eslint-disable-next-line no-undef
      new daum.roughmap.Lander({
        timestamp: '1649610704648',
        key: '29sb6',
        mapWidth: '1104',
        mapHeight: '400',
      }).render()
      setIsLoaded(true)
    }
  }, [])

  const send = (e) => {
    const form = formRef.current

    const { company, email, tel, content } = form
    if (!company.value || !email.value || !tel.value || !content.value) {
      notification.warn({
        message: '입력되지 않은 항목이 있습니다',
        placement: 'bottom',
        duration: 2,
      })
      return
    }
    if (form.querySelector('#agree').checked === false) {
      notification.warn({
        message: '개인정보 수집에 이용동의 해주셔야 전송이 가능합니다.',
        placement: 'bottom',
        duration: 2,
      })
      return
    }

    const formData = new FormData()
    formData.append('file', form.file)
    formData.append('company', company.value)
    formData.append('email', email.value)
    formData.append('tel', tel.value)
    formData.append('content', content.value)
    if (confirm('작성하신 내용으로 전송하시겠습니까?')) {
      axios
        .post('/api/contact', formData, {
          header: { 'content-type': 'multipart/form-data' },
        })
        .then((res) => {
          notification.info({
            message: '전송되었습니다. 문의해 주셔서 감사합니다.',
            placement: 'bottom',
            duration: 2,
          })
          form.reset()
        })
        .catch((e) => {
          notification.error({
            message: e.response?.data || e.message,
            placement: 'bottom',
            duration: 2,
          })
        })
    }

    // debugger
    // alert('기능 준비중입니다.\n너그러운 마음으로 조금만 기다려주세요')
  }

  return (
    <>
      <article className={styles['contact']}>
        <Header
          title={'CONTACT'}
          discription={'문의 및 회사 위치 안내'}
          backgroundImg={BackgroundImg}
        />
        <section className={styles['content']}>
          <div className={styles['container']}>
            <div className={styles['left']}>
              <h1>
                플랜이슈와 함께
                <br />
                성공적인 프로젝트를
                <br />
                만드세요.
              </h1>
              <img src={IconArrowRed} />
            </div>
            <div className={styles['right']}>
              <form ref={formRef}>
                <label>
                  <h2>단체 or 회사명</h2>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    maxLength={100}
                  />
                </label>
                <label>
                  <h2>이메일</h2>
                  <input type="text" id="email" name="email" maxLength={100} />
                </label>
                <label>
                  <h2>연락처</h2>
                  <input type="text" id="tel" name="tel" maxLength={20} />
                </label>
                <label>
                  <h2>의뢰/문의 내용</h2>
                  <input
                    type="text"
                    id="content"
                    name="content"
                    maxLength={10000}
                  />
                </label>
                {/* <label>
                  <h2>파일첨부</h2>
                  <input type="file" id="file" name="file" />
                </label> */}
                <div className={styles['input-buttom']}>
                  <label className={styles['personal-information-check']}>
                    <input
                      type="checkbox"
                      id="agree"
                      name="agree"
                      value={agreed}
                      onClick={() => setAgreed(!agreed)}
                    />
                    <span className={styles['checkmark']}></span>
                    개인정보 수집·이용 동의
                  </label>
                  <div className={styles['button']} onClick={send}>
                    보내기
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section className={`${styles['content']} ${styles['location']}`}>
          <div className={styles['container']}>
            <div className={styles['left']}>
              <h1>
                플랜이슈
                <br />
                찾아 오시는 길
              </h1>
              <img src={IconArrowRed} />
            </div>
            <div className={styles['right']}>
              <h2>Address</h2>
              <h3>서울시 마포구 동교로 23길 32-18 2층 ㈜플랜이슈</h3>
              <h2>Tel</h2>
              <h3>02 337 7983</h3>
              <h2>Fax</h2>
              <h3>02 337 7986</h3>
              <h2>Email</h2>
              <h3>samsara12@hanmail.net</h3>
            </div>
          </div>
          <div className={styles['map']}>
            <div
              id="daumRoughmapContainer1649610704648"
              className="root_daum_roughmap root_daum_roughmap_landing"
            ></div>
          </div>
        </section>
      </article>
      <Footer />
    </>
  )
}

export default Contact
