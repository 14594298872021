import { Button, Form, Input, notification } from 'antd'
import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { userStore } from 'store/UserStore'
import styles from './Login.module.scss'

export const Login = () => {
  const navigate = useNavigate()
  const login = userStore((state) => state.login)

  const onFinish = (values) => {
    const url = '/api/users/login'
    axios
      .post(`${url}`, {
        id: values.id,
        password: values.password
      })
      .then((res) => {
        login()
        navigate('/admin')
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          notification.error({
            message: e?.response?.data?.message,
            placement: 'bottom',
            duration: 2,
          })
        } else {
          notification.error({
            message: '에러가 발생했습니다. 다시 시도해 주세요.',
            placement: 'bottom',
            duration: 2,
          })
        }
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <section className={styles.login}>
      <Form
        className={styles['login-form']}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="id"
          name="id"
          rules={[
            {
              required: true,
              message: '아이디를 입력해 주세요',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: '패스워드를 입력해 주세요',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}
