import React, { useState } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'
import styles from './KeyMembers.module.scss'
import './KeyMembers.scss'
import 김민정 from 'static/images/key-members/김민정.png'
import 김민정_gray from 'static/images/key-members/김민정_gray.png'
import 김진성 from 'static/images/key-members/김진성.png'
import 김진성_gray from 'static/images/key-members/김진성_gray.png'
import 이소연 from 'static/images/key-members/이소연.png'
import 이소연_gray from 'static/images/key-members/이소연_gray.png'
import 이슬아 from 'static/images/key-members/이슬아.png'
import 이슬아_gray from 'static/images/key-members/이슬아_gray.png'
import 박서희 from 'static/images/key-members/박서희.png'
import 박서희_gray from 'static/images/key-members/박서희_gray.png'
import 최윤석 from 'static/images/key-members/최윤석.png'
import 최윤석_gray from 'static/images/key-members/최윤석_gray.png'
import 이욱 from 'static/images/key-members/이욱.png'
import 이욱_gray from 'static/images/key-members/이욱_gray.png'

import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react'
import { Scrollbar, Pagination, Navigation } from 'swiper'

const Member = ({
  imgSrc,
  imgSrcGray,
  name,
  title,
  englishName,
  discription,
}) => {
  const swiper = useSwiper()
  const swiperSlide = useSwiperSlide()

  console.log(swiperSlide.isActive)
  return (
    <div className={styles['member']}>
      <img
        src={swiperSlide.isActive ? imgSrc : imgSrcGray}
        style={{
          width: swiperSlide.isActive ? '366px' : '210px',
          marginTop: swiperSlide.isActive ? '0' : '110px',
          opacity: swiperSlide.isActive ? '1' : '0.5',
        }}
      />
      {swiperSlide.isActive ? (
        <section className={styles['memeber-introduction']}>
          <div className={styles['memeber-introduction-contents']}>
            <div className={styles['name-area']}>
              <span className={styles['member-name']}>{name}</span>
              <span className={styles['member-title']}>{title}</span>
            </div>
            <div className={styles['english-name']}>{englishName}</div>
            <div
              className={styles['member-discription']}
              dangerouslySetInnerHTML={{ __html: discription }}
            ></div>
          </div>
        </section>
      ) : null}
    </div>
  )
}
const KeyMembers = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isVisible = true
  return (
    <section className={`container key-members ${styles['key-members']}`}>
      <ReactVisibilitySensor
        onChange={(isVisible) => isVisible && setIsLoaded(true)}
      >
        {({ isVisible }) => (
          <article>
            <h1 className={styles['top']}>
              <div
                className={`${styles['top-content']} ${
                  isVisible || isLoaded ? styles['rise-up'] : ''
                }`}
              >
                Key Members
              </div>
            </h1>
            <section
              className={`${styles['content']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <section className={styles['discription']}>
                플랜이슈에는 무엇이든 만들어내는 해결사 같은 멤버들이
                가득합니다.
              </section>
              <section className={styles['discription']}>
                프로젝트 성공을 위해 열심히 달려가는 보석같은 리더들을
                소개합니다.
              </section>
              <section
                className={`${styles['members']} ${
                  isVisible || isLoaded ? styles['fadein'] : ''
                }`}
              >
                <Swiper
                  centeredSlides={true}
                  slidesPerView={1}
                  spaceBetween={30}
                  breakpoints={{
                    925: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    1588: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },
                  }}
                  navigation={true}
                  loop={true}
                  modules={[Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <Member
                      imgSrc={김진성}
                      imgSrcGray={김진성_gray}
                      name="김진성"
                      title="대표"
                      englishName="Gregory Kim"
                      discription="열정과 목적을 가지고<br>제대로 놀 줄 아는<br>플랜이슈 용역장인 "
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Member
                      imgSrc={박서희}
                      imgSrcGray={박서희_gray}
                      name="박서희"
                      title="이사"
                      englishName="Park, Seo-Hee"
                      discription="비평적인 관점과 뛰어난<br>통찰력을 가진 뛰어난 전략가"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Member
                      imgSrc={이소연}
                      imgSrcGray={이소연_gray}
                      name="이소연"
                      title="실장"
                      englishName="Lee, So-Yeoun"
                      discription="따뜻한 감성을 가지고<br>활력적으로 플랜이슈를<br>이끄는 총괄자"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Member
                      imgSrc={김민정}
                      imgSrcGray={김민정_gray}
                      name="김민정"
                      title="실장"
                      englishName="Kim Min Jung"
                      discription="조경과 관광컨설팅<br>전문성을 모두 갖춘<br>자타공인 멀티플레이어"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Member
                      imgSrc={이슬아}
                      imgSrcGray={이슬아_gray}
                      name="이슬아"
                      title="팀장"
                      englishName="Seula Lee"
                      discription="창의적 감각으로<br>새로운 것에 도전하는<br>영지니어스"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Member
                      imgSrc={최윤석}
                      imgSrcGray={최윤석_gray}
                      name="최윤석"
                      title="팀장"
                      englishName="CHOI Yoonseok"
                      discription="상상력이 풍부하고 독창적인<br>자유분방 예술가"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Member
                      imgSrc={이욱}
                      imgSrcGray={이욱_gray}
                      name="이욱"
                      title="팀장"
                      englishName="Lee Uk"
                      discription="장점과 기회를<br>잘 활용할 줄 아는<br>꼼꼼한 분석가"
                    />
                  </SwiperSlide>
                </Swiper>
              </section>
            </section>
          </article>
        )}
      </ReactVisibilitySensor>
    </section>
  )
}

export default KeyMembers
