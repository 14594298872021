import React, { useEffect, useState } from 'react'
// import React, { useEffect, useState } from 'react'
import styles from './Intro.module.scss'
import IntroVideo from 'static/videos/intro.mp4'
import LogoWhite from 'static/images/logo-first-wh@3x.png'
import LogoRed from 'static/images/logo-first-red@3x.png'
import ReactVisibilitySensor from 'react-visibility-sensor'

const Intro = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isOdd, setIsOdd] = useState(false)
  const [onLoad, setOnload] = useState(false)
  useEffect(() => {
    let it
    setTimeout(() => {
      it = setInterval(() => {
        setIsOdd((isOdd) => !isOdd)
      }, 500)
    }, 1000)
    return () => {
      clearInterval(it)
    }
  }, [])
  useEffect(() => {
    setOnload(true)
    return () => {
      setOnload(false)
    }
  }, [])
  const isVisible = true
  return (
    <section className={`container ${styles['intro']}`}>
      <video muted autoPlay loop>
        <source src={IntroVideo} type="video/mp4"></source>
      </video>
      <ReactVisibilitySensor
        onChange={(isVisible) => isVisible && setIsLoaded(true)}
      >
        {({ isVisible }) => (
          <article className={styles['content']}>
            <section className={`${styles['logo-container']}`}>
              {/*               
              <div
                className={`${styles['logo-img']} ${
                  isVisible || isLoaded ? styles['rise-up'] : ''
                } ${isOdd ? styles['logo-white'] : styles['logo-red']}`}
              /> 
              */}
              <img
                className={`${styles['logo-img']} ${
                  isVisible || isLoaded ? styles['rise-up'] : ''
                }`}
                src={isOdd ? LogoWhite : LogoRed}
              />
            </section>
            <h2
              className={`${styles['intro-discription']} ${
                isVisible || isLoaded ? styles['typing'] : ''
              }`}
            >
              <div>
                <span>
                  &lsquo;?&rsquo;에 &lsquo;<span className="red">!</span>
                </span>
                <span>
                  &rsquo;로 답하겠습니다
                </span>
              </div>
            </h2>
            <section
              className={`${styles['middle']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <p>
                <span className={styles['red']}>플랜이슈?</span>라는 물음에{' '}
                <span className={styles['red']}>플랜이슈!</span>라는 느낌표를
                찍습니다.
              </p>
            </section>
            <section
              className={`${styles['bottom']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <p>느낌표의 길쭉한 선은 연구진이 고민하고 있는 생각의 길을</p>
              <p>아래의 점은 프로젝트의 마침표인 결과물을 표현합니다.</p>
            </section>
            <div className={styles['scroll-line-container']}>
              <div
                className={`${styles['scroll-line']} ${
                  isVisible || isLoaded ? styles['scroll'] : ''
                }`}
              ></div>
            </div>
            <div className={styles['mouse']}>
              <div className={styles['mouse-wheel']}></div>
            </div>
            <section className={styles['scroll-text']}>Scroll</section>
          </article>
        )}
      </ReactVisibilitySensor>
    </section>
  )
}

export default Intro
