import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import React, { useEffect, useState } from 'react'
import styles from './Interviews.module.scss'
import BackgroundImg from 'static/images/sub-img-2.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import FullscreenSpinner from 'components/FullscreenSpinner'

const Card = ({
  id,
  name,
  jobTitle,
  imgSrc,
  interviewImg,
  title,
  subTitle,
  content,
}) => (
  <Link
    to={`/consultant/interview/${id}`}
    state={{ imgSrc, interviewImg, title, subTitle, content }}
  >
    <article className={styles['card']}>
      <section className={styles['left']}>
        <img src={imgSrc} className={styles['img']} />
      </section>
      <section className={styles['right']}>
        <h3>{title}</h3>
        <section className={styles['name']}>
          {name}
          <span className={styles['job-title']}>{jobTitle}</span>
        </section>
      </section>
    </article>
  </Link>
)

const Interviews = () => {
  const url = '/api/interview'
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState()

  useEffect(() => {
    setLoading(true)
    axios
      .get(url)
      .then(({ data }) => {
        const responseData = data.data
        responseData.map((d, i, all) => {
          d.no = all.length - i + 1
          d.create_time = d.create_time.replace('T', ' ').substr(0, 19)
          return d
        })
        setDataSource(responseData)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <article className={styles['interviews']}>
        <Header
          title="인터뷰"
          discription="이슈메이커들이 전하는 직무 이야기"
          backgroundImg={BackgroundImg}
        />
        <article className={styles['contents']}>
          <section className={styles['content']}>
            {loading || !dataSource ? (
              <FullscreenSpinner />
            ) : (
              dataSource.map((d) => (
                <Card
                  key={d.setLoading}
                  id={d.id}
                  name={d.interviewee}
                  jobTitle={d.position}
                  imgSrc={d.profile_image}
                  title={d.title}
                  content={d.content}
                  interviewImg={d.header_image}
                />
              ))
            )}
          </section>
        </article>
      </article>
      <Footer />
    </>
  )
}
export default Interviews
