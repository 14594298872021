import React from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './Newsletter.module.scss'
import BackgroundImg from 'static/images/sub-img-4@3x.png'
import Newsletter2020 from 'static/images/플랜이슈 선데이서울 2020.jpg'
import Newsletter2021 from 'static/images/플랜이슈 타임 2021.jpg'
import Newsletter2022 from 'static/images/플랜이슈 행가집 2022.jpg'
import Newsletter2023 from 'static/images/플랜이슈 뉴스레터 2023.png'
import Newsletter2024 from 'static/images/플랜이슈 뉴스레터 2024.png'
import Newsletter2020Pdf from 'static/newsletter/Planissue_Newsletter_2020.pdf'
import Newsletter2021Pdf from 'static/newsletter/Planissue_Newsletter_2021.pdf'
import Newsletter2022Pdf from 'static/newsletter/Planissue_Newsletter_2022.pdf'
import Newsletter2023Pdf from 'static/newsletter/Planissue_Newsletter_2023.pdf'
import Newsletter2024Pdf from 'static/newsletter/Planissue_Newsletter_2024.pdf'

const NewsLetter = () => {
  // const openNewsletter = (year) => {
  //   debugger
  //   window.open(`/api/file/pdf/Planissue_Newsletter_${year}.pdf`)
  // }

  const Card = ({ year, title, imgSrc }) => {
    const pdf =
      year === '2020'
        ? Newsletter2020Pdf
        : year === '2021'
        ? Newsletter2021Pdf
        : year === '2022'
        ? Newsletter2022Pdf
        : year === '2023'
        ? Newsletter2023Pdf
        : year === '2024'
        ? Newsletter2024Pdf
        : null
    // debugger
    return (
      <section className={styles['card']}>
        <h1 className={styles['year']}>{year}</h1>
        <div className={styles['title']}>{title}</div>
        <a href={pdf} target="_blank" rel="noreferrer">
          <div className={styles['card-image-container']}>
            <img src={imgSrc} />
          </div>
        </a>
      </section>
    )
  }

  return (
    <article className={styles['newsletter']}>
      <Header
        title="뉴스레터"
        discription="플랜이슈만의 일년 스토리가 담긴 소식지"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']}>
        <section className={styles['container']}>
          <Card year="2024" title="Travel" imgSrc={Newsletter2024} />
          <Card year="2023" title="Decade" imgSrc={Newsletter2023} />
          <Card year="2022" title="행복이 가득한 집" imgSrc={Newsletter2022} />
          <Card year="2021" title="타임" imgSrc={Newsletter2021} />
          <Card year="2020" title="선데이서울" imgSrc={Newsletter2020} />
        </section>
      </section>
      <Footer />
    </article>
  )
}

export default NewsLetter
