import React from 'react'
import { BeatLoader, GridLoader } from 'react-spinners'
import styled from 'styled-components'

const FullscreenSpinner = () => {
  return (
    <S.DimmedLayer>
      <BeatLoader color={'#ed1c24'} />
    </S.DimmedLayer>
  )
}

const S = {
  DimmedLayer: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

export default FullscreenSpinner
