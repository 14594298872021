import Header from 'pages/components/Header'
import React from 'react'
import styles from './TalentWelfare.module.scss'
import Footer from 'pages/components/Footer'
import ImgPeople1 from 'static/images/img-people-01@3x.png'
import ImgPeople2 from 'static/images/img-people-02@3x.png'
import ImgPeople3 from 'static/images/img-people-03@3x.png'
import Icon01 from 'static/images/icon-01.svg'
import Icon02 from 'static/images/icon-02.svg'
import Icon03 from 'static/images/icon-03.svg'
import Icon04 from 'static/images/icon-04.svg'
import Icon05 from 'static/images/icon-05.svg'
import Icon06 from 'static/images/icon-06.svg'
import Icon07 from 'static/images/icon-07.svg'
import Icon08 from 'static/images/icon-08.svg'
import Icon09 from 'static/images/icon-09.svg'
import Icon10 from 'static/images/icon-10.svg'
import Icon11 from 'static/images/icon-11.svg'
import Icon12 from 'static/images/icon-12.svg'
import Icon13 from 'static/images/icon-13.svg'
import Icon14 from 'static/images/icon-14.svg'
import Icon15 from 'static/images/icon-15.svg'
import Icon16 from 'static/images/icon-16.svg'
import BackgroundImg from 'static/images/sub-img-1.png'

const Talent = ({ imgSrc, seq, title, children }) => (
  <section className={styles['talent']}>
    <div className={styles['container']}>
      <div className={styles['left']}>
        <img src={imgSrc} />
      </div>
      <div className={styles['right']}>
        <section>
          <h2>
            <span className={styles['seq']}>{seq}</span>
            {title}
          </h2>
          <section className={styles['content']}>{children}</section>
        </section>
      </div>
    </div>
  </section>
)

const Welfare = ({ imgSrc, title, children }) => (
  <section className={styles['welfare']}>
    <img src={imgSrc} />
    <section className={styles['title']}>{title}</section>
    <section className={styles['content']}>{children}</section>
  </section>
)

const TalentWelfare = () => (
  <>
    <article className={styles['talent-welfare']}>
      <Header
        title={'인재상 및 복리후생'}
        discription={'성장의 즐거움을 함께할 인재 채용'}
        backgroundImg={BackgroundImg}
      />
      <section className={styles['contents']}>
        <h1>
          관광컨설팅의 내일을 함께하는
          <br />
          플랜이슈 인재상
        </h1>
        <section className={styles['talents']}>
          <Talent
            imgSrc={ImgPeople1}
            seq="첫째, "
            title="사칙연산을 잘하는 사람"
          >
            <section className={styles['discription']}>
              <span className={styles['operation']}>+ </span>
              함께해서 더 좋은 결과물을 낳는 사람
            </section>
            <section className={styles['discription']}>
              <span className={styles['operation']}>- </span>
              과한 것들을 감하여 실행력을 높여주는 사람
            </section>
            <section className={styles['discription']}>
              <span className={styles['operation']}>× </span>
              힘든 업무과정에서 즐거움을 곱하여 여유를 갖게 하는 사람
            </section>
            <section className={styles['discription']}>
              <span className={styles['operation']}>÷ </span>
              타인의 어려움을 나누어 가져갈 수 있는 사람
            </section>
          </Talent>
          <Talent imgSrc={ImgPeople2} seq="둘째, " title="잘 노는 사람">
            관광컨설턴트는 누군가를 행복하게 즐길 수 있게 만드는 일이기에
            <br />
            관광컨설턴트 개인이 잘 놀 수 있어야 합니다.
            <br />
            즐겨본 자만이 남을 즐길 수 있게 하는 것은 당연합니다.
          </Talent>
          <Talent imgSrc={ImgPeople3} seq="셋째, " title="건강한 사람">
            관광컨설팅은 이래저래 많은 스트레스가 동반되는 일입니다.
            <br />
            자신의 정서적·정신적·체력적 건강을 유지하는 사람은 일도
            <br />
            잘한다는 사실은 진리라고 생각합니다.
          </Talent>
        </section>
      </section>
      <section className={styles['recruitment-banner']}>
        <div className={styles['left']}>
          <h3>플랜이슈 채용공고</h3>
          <h2>
            새로운 관점과 신선한 아이디어로 플랜이슈와 함께 걸어갈 인재를
            기다립니다.
          </h2>
        </div>
        <div className={styles['right']}>
          <div className={styles['button']} onClick={()=> window.open('https://www.saramin.co.kr/zf_user/company-info/view?csn=RmRVL2VvRVIvR3BmUnJiSWl3N1FWdz09', '_blank')} style={{ cursor: 'pointer' }}>채용공고 보러가기 &gt;</div>
        </div>
      </section>
      <section className={styles['welfares']}>
        <section className={styles['container']}>
          <h2>
            플랜이슈는 직원과 회사가 함께 성장할 수 있는
            <br />
            근무환경과 사내문화를 만들어갑니다.
          </h2>
          <section className={styles['welfare-container']}>
            <Welfare imgSrc={Icon01} title="법정복리후생">
              4대보험, 상해보험 등 임직원의 안정적인 회사생활을 보장합니다.
            </Welfare>
            <Welfare imgSrc={Icon02} title="월차제도">
              한 달에 한번 눈치 보지말고 자유롭게 사용하세요!
            </Welfare>
            <Welfare imgSrc={Icon04} title="연차제도">
              월차 외에도 자유를 보장하는 휴가제도가 있답니다! (3년 미만 5일,
              3년 이상 6일)
            </Welfare>
            <Welfare imgSrc={Icon03} title="장기근속 포상">
              3년/5년/7년/10년 근속마다 최대 1개월 휴가 및 휴가비 등을
              지원합니다.
            </Welfare>
            <Welfare imgSrc={Icon05} title="명절선물">
              명절(설, 추석 등) 전 날 조기퇴근과 함께 귀향 교통비를 드립니다.
            </Welfare>
            <Welfare imgSrc={Icon06} title="안마의자">
              임직원의 휴식을 위한 최고급 안마의자가 준비되어 있습니다. (a.k.a
              에드워드)
            </Welfare>
            <Welfare imgSrc={Icon07} title="해피벌스데이">
              화려한 생일파티, 케이크 그리고 선물까지! 행복한 하루를
              선사해드립니다.
            </Welfare>
            <Welfare imgSrc={Icon08} title="소풍데이">
              날씨가 좋을 때, 좋은 일이 있을 때, 그것도 아니면 갑자기! 함께
              나들이 떠나요!
            </Welfare>
            <Welfare imgSrc={Icon09} title="워크샵">
              이슈메이커들의 힐링과 단합을 위하여 <br />
              워크샵을 진행합니다. (해외 워크샵 포함)
            </Welfare>
            {/* 
            <Welfare imgSrc={Icon10} title="해외워크샵">
              보고 듣고 느끼고 즐기기 위해 1년에 한 번 해외로 워크샵을 갑니다.
            </Welfare>
             */}
            <Welfare imgSrc={Icon16} title="청년내일채움공제">
              플랜이슈 재직자를 위해 내일채움공제를 지원합니다.
            </Welfare>
            <Welfare imgSrc={Icon11} title="출산휴가/육아휴직">
              근로기준법에 따라 출산 및 육아휴직을 <br />
              지원합니다.
            </Welfare>
            <Welfare imgSrc={Icon12} title="도서구입 지원">
              역량강화를 위해 업무 시 참고할 책이 필요하다면 도서구입비를 지원합니다.
            </Welfare>
            <Welfare imgSrc={Icon13} title="사내 동아리 지원">
              취미ㆍ여가생활 증진, 워라밸 문화 정착을 위한 동아리 활동비 일부를
              지원합니다.
            </Welfare>
            <Welfare imgSrc={Icon14} title="경조사 지원">
              경조사 발생 시 경조 휴가 및 경조금 등을 지원해 드립니다.
            </Welfare>
            <Welfare imgSrc={Icon15} title="대학원 지원">
              업무 관련 대학 및 학과의 대학원 진학 시 학비 일부를 지원해 드려요.
            </Welfare>
          </section>
        </section>
      </section>
    </article>
    <Footer />
  </>
)
export default TalentWelfare
