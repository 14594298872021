import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import React from 'react'
import styles from './RepresentativeGreeting.module.scss'
import ImgBk from 'static/images/bk@3x.png'
import BackgroundImg from 'static/images/sub-img-2.png'

const RepresentativeGreeting = () => (
  <>
    <article className={styles['representative-greeting']}>
      <Header
        title={'대표이사'}
        discription={'대표이사 인사말'}
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']}>
        <section className={styles['left']}>
          <img src={ImgBk} />
        </section>
        <section className={styles['right']}>
          <h1>
            빨간 자켓의 비밀
            <span className={styles['sub-title']}>
              The secret of red jacket
            </span>
          </h1>
          <h2>그냥 이야기였습니다.</h2>
          <section>
            고등학생일때만 해도 교실에 있는 학생 60명 중에 한명일 뿐이었습니다.
            <br />
            특별히 잘하는 것도 없고, 특별히 못난 것도 없는…
            <br />
            그래도 항상 주변에는 제가 하는 ‘이야기’를 듣고자 하는 친구들이
            많았습니다.
          </section>
          <h2>주목하고 들어주기 시작했습니다.</h2>
          <section>
            대학에서는 이러한 저의 이야기에 많은 사람들이 주목하고 칭찬
            해주었습니다.
            <br />
            학교 뿐 아니라 각종 공모전 등에서도 저의 이야기가 인정받기
            시작했으며
            <br />
            저도 모르는 사이에 저는 사람들 사이에서 이야기꾼으로 불리어졌습니다.
          </section>
          <h2>믿어주셨습니다.</h2>
          <section>
            관광 컨설팅 업무만 21년째, 경쟁사회에 뛰어든 저는 빨간 자켓을
            입습니다.
            <br />
            빨간 자켓은 제 일에 대한 열정의 표시이고, 스스로에 대한
            자신감입니다.
            <br />
            그리고 치열한 시장속에서 저를 상징하는 차별요소이기도 합니다.
          </section>
          <h2>새로운 콘덴츠를 만들어드립니다.</h2>
          <section>
            관광산업은 빠르게 변화하는 유행산업이며 무엇보다도 트렌드와 이슈에
            민감한 사업이라 생각합니다.
            <br />
            예전처럼 그저 땅을 사고, 건물을 짓고, 입장료를 징수하는 사업으로
            관광을 생각하고 접근하는 컨설팅은 이제 잊으십시오.
          </section>
          <section style={{ marginTop: '49px' }}>
            필요하시다면, 용기가 없으시다면, 경쟁에서 이겨나가시고 싶으시다면,
            <br />
            이야기와 콘텐츠, 아이디어가 듬뿍 담겨진 저의 빨간 자켓을 나누어
            드리겠습니다.
          </section>
          <section className={styles['sign-container']}>
            2022.07.05 ㈜플랜이슈 대표
            <span className={styles['sign']}>김진성</span>
          </section>
        </section>
      </section>
    </article>
    <Footer />
  </>
)
export default RepresentativeGreeting
