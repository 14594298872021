import AdminLayout from 'pages/components/admin/AdminLayout'
import React from 'react'

const Index = () => {
  return (
    <article>
      <AdminLayout />
    </article>
  )
}

export default Index
