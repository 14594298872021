import React, { useState } from 'react'
import styles from './WhyPlanissue.module.scss'
import { ReactComponent as IconBattery } from 'static/images/icon-battery.svg'
import { ReactComponent as IconFamily } from 'static/images/icon-family.svg'
import { ReactComponent as IconMaker } from 'static/images/icon-maker.svg'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Scrollbar, Pagination, Navigation } from 'swiper'

const WhyPlanissue = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const Card1 = ({ isVisible }) => (
    <div
      className={`${styles['card']} ${styles['card1']} ${
        isVisible || isLoaded ? styles['fadein'] : ''
      }`}
    >
      <section className={styles['card-content']}>
        <div className={styles.icon}>
          <IconFamily />
        </div>
        <div>
          <h1>
            <span>가족같은 회사</span> <span>플랜이슈</span>
          </h1>
          <p className={styles.content}>
            임직원 모두 똘똘 뭉치는 플랜이슈 식구들은
            <br />
            언제나 단합하여 일을 추진하고
            <br />질 높은 성과품을 마련합니다.
          </p>
        </div>
      </section>
    </div>
  )

  const Card2 = ({ isVisible }) => (
    <div
      className={`${styles['card']} ${styles['card2']} ${
        isVisible || isLoaded ? styles['fadein'] : ''
      }`}
    >
      <section className={styles['card-content']}>
        <div className={styles.icon}>
          <IconMaker />
        </div>
        <div>
          <h1>
            <span>발 빠른</span> <span>이슈메이커</span>
          </h1>
          <p className={styles.content}>
            플랜이슈는 새로운 접근방식과 시각으로
            <br />
            항상 새로운 관광아이템을 제시하며
            <br />
            이슈화와 선점의 원칙을 고려합니다.
          </p>
        </div>
      </section>
    </div>
  )

  const Card3 = ({ isVisible }) => (
    <div
      className={`${styles['card']} ${styles['card3']} ${
        isVisible || isLoaded ? styles['fadein'] : ''
      }`}
    >
      <section className={styles['card-content']}>
        <div className={styles.icon}>
          <IconBattery />
        </div>
        <div>
          <h1>
            <span>오래가는</span> <span>건전지</span>
          </h1>
          <p className={styles.content}>
            과업기간만 채우고 &rsquo;안녕&lsquo;하는 타 업체와는
            <br />
            다릅니다. 항상 고객을 생각하고 연락하는
            <br />
            사람 냄새나는 기업입니다.
          </p>
        </div>
      </section>
    </div>
  )
  const isVisible = true
  return (
    <section className={`container ${styles['why-planissue']}`}>
      <ReactVisibilitySensor
        onChange={(isVisible) => isVisible && setIsLoaded(true)}
      >
        {({ isVisible }) => (
          <article
          // style={{ backgroundColor: isVisible ? 'red' : 'blue' }}
          >
            <h1 className={styles['top']}>
              <div
                className={`${styles['top-content']} ${
                  isVisible || isLoaded ? styles['rise-up'] : ''
                }`}
              >
                Why Planissue?
              </div>
            </h1>
            <section
              className={`${styles['middle']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <h3>
                플랜이슈는 관광컨설팅을 통해 의뢰인이 원하는 콘텐츠를 개발하여
                드립니다.
              </h3>
              <h3>
                이야기를 현실화 시키고 현실화 된 이야기를 이슈화시켜 드립니다.
                예전처럼 단순한 컨설팅은 이제 잊으세요.
              </h3>
            </section>
            <section className={styles['bottom']}>
              <h2
                className={`${styles['bottom-content']} ${
                  isVisible || isLoaded ? styles['typing'] : ''
                }`}
              >
                <span>플랜이슈의 이야기와 콘텐츠,</span>{' '}
                <span>아이디어로 빨갛게 물들이겠습니다.</span>
              </h2>
            </section>
            {/* {window.innerWidth > 1160 ? ( */}
            <section className={styles['cards']}>
              <Card1 isVisible={isVisible} />
              <Card2 isVisible={isVisible} />
              <Card3 isVisible={isVisible} />
            </section>
            {/* ) : (
          <section className={styles['cards']}>
            <Swiper
              centeredSlides={true}
              slidesPerView={3}
              spaceBetween={30}
              navigation={true}
              loop={true}
              modules={[Pagination, Navigation]}
            >
              <SwiperSlide>
                <Card1 isVisible={isVisible} />
              </SwiperSlide>
              <SwiperSlide>
                <Card2 isVisible={isVisible} />
              </SwiperSlide>
              <SwiperSlide>
                <Card3 isVisible={isVisible} />
              </SwiperSlide>
            </Swiper>
          </section>
        )} */}
          </article>
        )}
      </ReactVisibilitySensor>
    </section>
  )
}

export default WhyPlanissue
