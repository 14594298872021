import React, { useState } from 'react'
import './BusinessArea.scss'
import styles from './BusinessArea.module.scss'
import ImgBusinessArea01 from 'static/images/business-01.png'
import ImgBusinessArea02 from 'static/images/business-02.png'
import ImgBusinessArea03 from 'static/images/business-03.png'
import ImgBusinessArea04 from 'static/images/business-04.png'
import ReactVisibilitySensor from 'react-visibility-sensor'
import LogoBigGray from 'static/images/logo-big-gray@3x.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Scrollbar, Pagination, Navigation } from 'swiper'

const Card = ({ title, content, src, link }) => (
  <div className={styles['card']}>
    <section className={styles['img-area']}>
      <img src={src} />
    </section>
    <section
      className={styles['responsive-contents']}
      style={{
        display: 'none',
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
      }}
    ></section>
    <section className={styles['contents']}>
      <h1>{title}</h1>
      <p>{content}</p>
      <a href={link}>프로젝트 보기</a>
    </section>
    <section
      className={styles['responsive-contents-content']}
      style={{ display: 'none' }}
    >
      <p>{content}</p>
    </section>
  </div>
)
// const Card = () => <>Card</>

const BusinessArea = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isVisible = true
  return (
    <section className={`container business-area ${styles['business-area']}`}>
      <ReactVisibilitySensor
        onChange={(isVisible) => isVisible && setIsLoaded(true)}
      >
        {({ isVisible = true }) => (
          <article
          // style={{ backgroundColor: isVisible ? 'red' : 'blue' }}
          >
            <h1 className={styles['top']}>
              <div
                className={`${styles['top-content']} ${
                  isVisible || isLoaded ? styles['rise-up'] : ''
                }`}
              >
                Business Area
              </div>
            </h1>
            <section
              className={`${styles['middle']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <p>
                플랜이슈는 문화체육관광부, 한국관광공사가 인증한 국내 유일의
                관광벤처 출신 관광 컨설팅 업체입니다.
              </p>
              <p>
                관광, 마케팅, 스토리텔링, 사업타당성 조사 등 다양한 프로젝트를
                수행하고 있으며
              </p>
              <p>
                엔지니어링 등 사업분야 확장을 통해 관광컨설팅 업계의 이슈로
                도약하고 있습니다.
              </p>
            </section>
            <section
              className={`${styles['cards']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <Swiper
                centeredSlides={true}
                navigation={true}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  1401: {
                    slidesPerView: 3,
                    spaceBetween: 680,
                  },
                }}
                // pagination={true}
                // scrollbar={{
                //   hide: false,
                // }}
                modules={[Scrollbar, Pagination, Navigation]}
              >
                <SwiperSlide>
                  <Card
                    title={'전략적 관광컨설팅'}
                    content={`국책공모사업기본계획, 지역관광종합개발계획, 광역관광개발계획까지 지역의 지속가능한 관광 발전을 위한 거시적인 전략 로드맵 수립`}
                    link={'/portfolio/strategic'}
                    src={ImgBusinessArea01}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    title={'관광진흥컨설팅'}
                    content={`지역만의 고유한 정체성을 바탕으로 관광 콘텐츠 및 스토리 발굴은 물론 관광 브랜드 기획 및 관광상품 운영까지 지역관광 활성화를 위한 구체적인 방향 제시`}
                    link={'/portfolio/tourism-promotion'}
                    src={ImgBusinessArea02}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    title={'운영관리 및 사업화'}
                    content={`관리운영계획, 사업타당성 분석 및 실행계획, 투자유치 자문 등 성공적인 사업 운영을 위한 실질적이고 분석적인 운영전략 제안`}
                    link={'/portfolio/oper-mgm-commecial'}
                    src={ImgBusinessArea04}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    title={'공간 및 관광개발사업'}
                    content={`축적된 지식과 기술력을 바탕으로 관광(단)지, 리조트, 단위 관광시설, 휴양지구계획 등 효율적이고 합리적인 맞춤형 관광개발 계획 수립`}
                    link={'/portfolio/space-tourism-dev'}
                    src={ImgBusinessArea03}
                  />
                </SwiperSlide>
              </Swiper>
            </section>
            <img src={LogoBigGray} className={styles['logo-big-gray']} />
          </article>
        )}
      </ReactVisibilitySensor>
    </section>
  )
}

export default BusinessArea
