import React, { useEffect } from 'react'
import { Layout, Menu, Breadcrumb } from 'antd'
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from '@ant-design/icons'
import './AdminLayout.scss'
import { Link, useNavigate } from 'react-router-dom'
import { userStore } from 'store/UserStore'
import LogoWhite from 'static/images/logo-wh@3x.png'

const { SubMenu } = Menu
const { Header, Content, Footer, Sider } = Layout

const AdminLayout = ({ children }) => {
  const navigate = useNavigate()
  const isLogin = userStore((state) => state.isLogin)

  useEffect(() => {
    if (!isLogin) {
      navigate('/admin/login')
    }
  }, [isLogin])

  return (
    <div id="components-layout-demo-top-side">
      <Layout>
        <Header className="header">
          <div className="logo">
            <Link to="/admin">
              <img src={LogoWhite} alt="PLAN!SSUE" />
            </Link>
          </div>
          {/*         
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          <Menu.Item key="1">nav 1</Menu.Item>
          <Menu.Item key="2">nav 2</Menu.Item>
          <Menu.Item key="3">nav 3</Menu.Item>
        </Menu> */}
        </Header>
        {/* <Content style={{ padding: '0 50px' }}> */}
        {/*         
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>
         */}
        <Layout
          className="site-layout-background"
          style={{ padding: '24px 0' }}
        >
          <Sider className="site-layout-background" width={200}>
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ height: '100%' }}
              openKeys={['sub1', 'sub2', 'sub3', 'sub4']}
            >
              {/* <SubMenu key="sub1" icon={<UserOutlined />} title="About">
                <Menu.Item key="sub1-1">
                  <Link to="/admin/contact">의뢰/문의</Link>
                </Menu.Item> */}
                {/* 
                <Menu.Item key="sub1-2">
                  <Link to="/admin/board">탐구생활</Link>
                </Menu.Item>
                <Menu.Item key="sub1-3">
                  <Link to="/admin/board">공지사항</Link>
                </Menu.Item>
                <Menu.Item key="sub1-4">option4</Menu.Item>
                 */}
              {/* </SubMenu> */}
              <SubMenu key="sub2" icon={<LaptopOutlined />} title="Cunsultant">
                <Menu.Item key="sub2-1">
                  <Link to="/admin/interview">인터뷰</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub3" icon={<LaptopOutlined />} title="Portfolio">
                <Menu.Item key="3-1">
                  <Link to="/admin/portfolio">Portfolio</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub4" icon={<NotificationOutlined />} title="Story">
                <Menu.Item key="sub4-1">
                  <Link to="/admin/board" state={{ category: '공지사항' }}>
                    공지사항
                  </Link>
                </Menu.Item>
                <Menu.Item key="sub4-2">
                  <Link to="/admin/inquiry">탐구생활</Link>
                </Menu.Item>
                <Menu.Item key="sub4-3">
                    <Link to="/admin/insight">인사이트</Link>
                  </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: '0 24px', minHeight: 280 }}>
            {children}
          </Content>
        </Layout>
        {/* </Content> */}
        <Footer style={{ textAlign: 'center' }}>Planissue admin</Footer>
      </Layout>
    </div>
  )
}

export default AdminLayout
