import { Breadcrumb, Button, Input, Select, Table } from 'antd'
import axios from 'axios'
import AdminLayout from 'pages/components/admin/AdminLayout'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addQueryParameter } from 'utils'
import styles from './Board.module.scss'

export const Interview = () => {
  const [dataSource, setDataSource] = useState()
  const baseUrl = '/api/interview'
  const [url, setUrl] = useState(baseUrl)
  const [loading, setLoading] = useState(false)
  const [searchOption, setSearchOption] = useState('title')
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()

  const columns = [
    {
      title: 'no',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '인터뷰이',
      dataIndex: 'interviewee',
      key: 'interviewee',
    },
    {
      title: '직급',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: '등록일',
      dataIndex: 'create_time',
      key: 'create_time',
    },
  ]

  useEffect(() => {
    setLoading(true)
    axios
      .get(url)
      .then(({ data }) => {
        const responseData = data.data
        responseData.map((d, i, all) => {
          d.no = all.length - i + 1
          d.create_time = d.create_time.replace('T', ' ').substr(0, 19)
          return d
        })
        setDataSource(data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const selectBefore = (
    <Select
      defaultValue={'title'}
      onChange={(value) => {
        setSearchOption(value)
      }}
    >
      <Select.Option key="title">제목</Select.Option>
      <Select.Option key="content">내용</Select.Option>
    </Select>
  )

  const search = () => {
    let urlWithQuery = addQueryParameter(baseUrl, 'searchOption', searchOption)
    urlWithQuery = addQueryParameter(urlWithQuery, 'searchValue', searchValue)
    setUrl(urlWithQuery)
  }

  const write = () => {
    navigate(`/admin/interview/write`)
  }

  const goToDetail = (record) => {
    const { id, title, content, interviewee, position, profile_image, header_image } = record
    navigate(`/admin/interview/write`, { state: { id, title, content, interviewee, position, profile_image, header_image } })
  }

  return (
    <AdminLayout>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Consultant</Breadcrumb.Item>
        <Breadcrumb.Item>인터뷰</Breadcrumb.Item>
        <Breadcrumb.Item>목록</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles['button-area']} style={{ marginBottom: '20px' }}>
        <div>
          <Input
            allowClear
            style={{ width: '300px' }}
            addonBefore={selectBefore}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={search}
            value={searchValue}
            autoFocus={true}
          />
          <Button type="primary" onClick={search}>
            검색
          </Button>
        </div>
        <Button type="primary" onClick={write}>
          글쓰기
        </Button>
      </div>
      <Table
        className={styles.table}
        dataSource={dataSource}
        columns={columns}
        pagination={{ position: ['bottomCenter'] }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
                goToDetail(record)
            },
          }
        }}
      ></Table>
    </AdminLayout>
  )
}
