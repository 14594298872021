import Header from 'pages/components/Header'
import React from 'react'
import styles from './Introduction.module.scss'
import ImgRed1 from 'static/images/img-red-1.png'
import ImgRed2 from 'static/images/img-red-2.png'
import ImgRed3 from 'static/images/img-red-3.png'
import Footer from 'pages/components/Footer'
import BackgroundImg from 'static/images/sub-img-1.png'

const Principle = ({ principle, title, children }) => (
  <article className={styles['card']}>
    <h2>{principle}</h2>
    <h1>{title}</h1>
    <section className={styles['content']}>{children}</section>
  </article>
)

const Content = ({ title, hashes, children }) => (
  <article className={styles['card']}>
    <h2>{title}</h2>
    <section className={styles['right-content']}>{children}</section>
    <section className={styles['hashes']}>{hashes}</section>
  </article>
)

const Introduction = () => (
  <>
    <article className={styles['introduction']}>
      <Header
        title={'플랜이슈 소개'}
        discription={
          '<span>관광컨설팅 업체와 차별화된</span> <span>플랜이슈만의 ‘다름’</span>'
        }
        backgroundImg={BackgroundImg}
      />
      <section className={styles['intro']}>
        <h1 className={styles['title']}>플랜이슈의 약속</h1>
        <section className={`${styles['content']} ${styles['p1']}`}>
          무슨 좋은 계획 있으십니까? <br />
          저희 ㈜플랜이슈에서는 바로 실행에 옮길 수 없었던 기존 관광컨설팅의
          한계를 넘고자 <br />
          관광마케팅 분야를 더욱 강화시켜 빠른 사업 실행을 약속드리겠습니다.
        </section>
        <section className={`${styles['content']} ${styles['p2']}`}>
          ㈜플랜이슈는 관광컨설팅을 통하여 의뢰인이 원하는 콘텐츠를 개발하여
          드립니다.
          <br />
          고객의 이야기를 현실화 시키며, 현실화 된 이야기를 이슈화 시키고
          <br />
          사업 차별화를 구현해드리겠습니다.
        </section>
        <section className={`${styles['content']} ${styles['p3']}`}>
          저마다 차별화를 이야기하는 지금
          <br />
          ‘사회적으로 이슈화’ 가능한 계획을 마련하는데 도움이 되어드리겠습니다.
          <br />
          그래서 ㈜플랜이슈는 이렇게 외칩니다.
        </section>
      </section>
      <div style={{ position: 'relative' }}>
        <div className={styles['speech-bubble']}>
          “뭐~ 좋은 계획(Plan) 있슈?”
        </div>
      </div>
      <section className={styles['three-principles']}>
        <Principle principle="Creation" title="창조 경영">
          의뢰인을 위함입니다.
          <br />
          경쟁시대에 살아남을 수 있는 진정한 차별화를 구현하겠습니다.
          <br />
          모든 계획에 있어 관광마케팅 방안을 수립하여 드리겠습니다.
        </Principle>
        <Principle principle="Fun" title="펀 경영">
          플랜이슈 임직원을 위함입니다.
          <br />
          더 좋은 아이템과 성과를 도출하기 위하여 직원의 복지에 힘쓰고
          <br />
          즐겁게 일할 수 있는 회사로서 펀(Fun)경영을 실시하겠습니다.
        </Principle>
        <Principle principle="Fairness" title="착한 경영">
          더 나은 사회로 나아가기 위함입니다.
          <br />
          기업의 이윤만을 쫓지 않겠습니다. 고객과의 약속을 최우선으로 하며,
          <br />
          국내 관광산업 발전에 이바지하는 정직한 마음가짐으로 일하겠습니다.
        </Principle>
      </section>
      <section className={styles['contents']}>
        <section className={styles['images']}>
          <img src={ImgRed1} />
          <img src={ImgRed2} />
          <img src={ImgRed3} />
        </section>
        <section className={styles['content']}>
          <section className={styles['left']}>
            <h1>
              빨간색은 플랜이슈의
              <br />
              시그니처 컬러입니다.
            </h1>
          </section>
          <section className={styles['right']}>
            <Content
              title="빨간색은 에너지, 열정을 의미합니다."
              hashes="#이벤트 #복지제도 #워크숍 #출장의_묘미는_식사 #작은아빠_같은_대표님"
            >
              다양한 분야, 고객과 만날 수 있는 관광컨설팅 업무는 새롭고 재미있는
              일입니다.
              <br />
              그러나 야근과 출장, 업무갈등으로 신체·정신적 스트레스가 많은
              직종이기도 합니다.
              <br />
              플랜이슈는 플랜이슈만의 스트레스 해소법을 통해 이를 극복해나가며
              항상 열정적으로 과업을 대하는 자세를 우선 덕목으로 삼아 과업
              하나하나에 소홀함이 없도록 노력하고 있습니다.
            </Content>
            <Content
              title="빨간색은 사랑, 따뜻함을  의미합니다."
              hashes="#소통 #굿리스너 #성과품은_또_하나의_생명 #고객님_사랑합니다 #전국의_관광과_만세"
            >
              관광 컨설팅의 시작은 고객의 이야기를 들어주는 ‘청취’에서 시작되고
              공감과 공유를 통해 감동을 선사해드리고자 노력하고 있습니다.
              플랜이슈가 추구하는 정서적 교류를 통해 최종 목표(Goal)를
              달성하고자 노력하고 있으며 특히 일에 대한 사랑과 고객에 대한
              신뢰를 매우 중요한 요소로 생각하고 있습니다.
            </Content>
            <Content
              title="빨간색은 정의, 용기를  의미합니다."
              hashes="#착한경영 #사랑받는_관광 #불편하고_비겁한_세상에_도전장 #성과품은_플랜이슈만의_창작물 #플랜이슈의_저작권을_지켜주세요"
            >
              플랜이슈의 모토는 ‘착한 경영’입니다. 수년간 현장에서 직접 뛰고
              다양한 사업을 겪어 나가면서 저희가 단 한번도 생각해본 적 없는
              수많은 형태의 부정과 불합리, 불공정을 마주치게 되었습니다.
              <br />
              그럴수록 플랜이슈는 이 불편한 진실에 단호한 자세를 취하기 위해
              노력합니다.
              <br />
              저희는 대한민국 국민이 사랑하는 관광컨텐츠를 만들고 선도하는
              진정한 관광컨설턴트입니다. 따라서 그 누구보다 엄격하게 정의를
              지키고 어떤 방해물도 용납하지 않을 용기를 무기 삼아 일하고
              있습니다.
            </Content>
          </section>
        </section>
      </section>
    </article>
    <Footer />
  </>
)

export default Introduction
