import FullscreenSpinner from 'components/FullscreenSpinner'
import useFetch from 'hooks/useFetch'
import { API_URL } from 'myconstant'
import AdminLayout from 'pages/components/admin/AdminLayout'
import React, { useCallback, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/react-editor'
import { Breadcrumb, Button, Input, message, notification, Select, Table } from 'antd'
import { useEffect } from 'react'
import axios from 'axios'
import { addQueryParameter } from 'utils'
import date from 'date-and-time'
import styles from './Board.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'

const AdminPortfolio = () => {
  const [category, setCategory] = useState('주요 프로젝트')
  const [dataSource, setDataSource] = useState([])
  // const [columns, setColumns] = useState([])
  const baseUrl = '/api/board'
  const [url, setUrl] = useState(baseUrl)
  const [loading, setLoading] = useState(false)
  const [searchOption, setSearchOption] = useState('title')
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()

  const columns = [
    {
      title: 'no',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '구분',
      dataIndex: 'sub_category_select',
      key: 'sub_category_select',
    },
    {
      title: '등록일',
      dataIndex: 'create_time',
      key: 'create_time',
    },
  ]

  //title, register_id, notice, category, content, image, hit, create_time, update_time

  useEffect(() => {
    setLoading(true)
    axios
      .get(url, {
        params: {
          category: '주요 프로젝트',
        },
      })
      .then(({ data }) => {
        const responseData = data.data
        responseData.forEach((d, i, all) => {
          d.no = all.length - i + 1
          d.create_time = d.create_time.replace('T', ' ').substr(0, 19)
          d.sub_category_select = (
            <Select
              defaultValue={d.sub_category}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onChange={(key) => changeCagegory(d.id, key)}
            >
              <Select.Option key="전략적 관광컨설팅">
                전략적 관광컨설팅
              </Select.Option>
              <Select.Option key="관광진흥컨설팅">관광진흥컨설팅</Select.Option>
              <Select.Option key="운영관리 및 사업화">
                운영관리 및 사업화
              </Select.Option>
              <Select.Option key="공간 및 관광개발사업">
                공간 및 관광개발사업
              </Select.Option>
            </Select>
          )
        })
        setDataSource(data.data)
      })
      .catch((e) => {
        debugger
        notification.error({
          message: e.response?.data || e.message,
          placement: 'bottom',
          duration: 2,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [url])

  const changeCagegory = (id, sub_category) => {
    axios
      .patch(`/api/board`, {
        id,
        sub_category,
      })
      .then((res) => {
        notification.info({
          message: '카테고리를 변경했습니다.',
          placement: 'bottom',
          duration: 2,
        })
      })
      .catch((e) => {
        debugger
        notification.error({
          message: e.response?.data || e.message,
          placement: 'bottom',
          duration: 2,
        })
      })
  }
  const selectBefore = (
    <Select
      defaultValue={'title'}
      onChange={(value) => {
        setSearchOption(value)
      }}
    >
      <Select.Option key="title">제목</Select.Option>
      <Select.Option key="content">내용</Select.Option>
    </Select>
  )

  const submit = () => {
    let urlWithQuery = addQueryParameter(baseUrl, 'searchOption', searchOption)
    urlWithQuery = addQueryParameter(urlWithQuery, 'searchValue', searchValue)
    setUrl(urlWithQuery)
  }

  const write = () => {
    navigate(`/admin/board/write`, { state: { category } })
  }

  const goToDetail = (record) => {
    console.log({ ...record })

    const { id, title, content, category, sub_category, image } = record
    navigate(`/admin/board/write`, {
      state: { id, title, content, category, sub_category, image },
    })
  }

  return (
    <AdminLayout>
      {loading ? (
        <FullscreenSpinner />
      ) : (
        <>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Portfolio</Breadcrumb.Item>
            <Breadcrumb.Item>Portfolio</Breadcrumb.Item>
            <Breadcrumb.Item>목록</Breadcrumb.Item>
          </Breadcrumb>
          <div
            className={styles['button-area']}
            style={{ marginBottom: '20px' }}
          >
            <Input.Group compact>
              <Input
                allowClear
                style={{ width: '300px' }}
                addonBefore={selectBefore}
                onChange={(e) => setSearchValue(e.target.value)}
                onPressEnter={submit}
                value={searchValue}
                autoFocus={true}
              />
              <Button type="primary" onClick={submit}>
                검색
              </Button>
            </Input.Group>
            <Button type="primary" onClick={write}>
              글쓰기
            </Button>
          </div>
          <Table
            className={styles.table}
            dataSource={dataSource}
            columns={columns}
            pagination={{ position: ['bottomCenter'] }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  goToDetail(record)
                }, // click row
              }
            }}
          ></Table>
        </>
      )}
    </AdminLayout>
  )
}

export default AdminPortfolio
