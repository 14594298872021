import React, { useEffect, useRef, useState } from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './Portfolio.module.scss'
import BackgroundImg from 'static/images/sub-img-3@3x.png'
import PortpolioImg from 'static/images/portpolio-sample.png'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { BeatLoader } from 'react-spinners'
import decode from 'unescape'
import LogoImg from 'static/images/members/Member_logo.png'
import EyeImg from 'static/images/eye.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import FullscreenSpinner from 'components/FullscreenSpinner'

const Portfolio = () => {
  const { targetMenu = 'strategic' } = useParams()
  const [menu, setMenu] = useState(targetMenu)
  const [type, setType] = useState('전략적 관광컨설팅')
  const [discription, setDiscription] = useState('')
  const activeClassName = styles['active']
  const baseUrl = '/api/board'
  const [url, setUrl] = useState(baseUrl)
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [startIdx, setStartIdx] = useState(0)
  const rows = 6
  const targetEle = useRef()

  useEffect(() => {
    refresh()
    const type = getType(targetMenu)
    setType(() => type)
    const discription = getDiscription(targetMenu)
    setDiscription(discription)
    setMenu(targetMenu)
    setLoading(true)
    fetchData(type)
    // targetEle.current.scrollIntoView()
  }, [url, menu, targetMenu])

  // TODO : unmount 시에 현재 dataSource와 스크롤 위치를 전역에 저장
  // detail page에서 이동시에 flag 전달
  // 해당 flag에서 다시 돌아온 경우에는 기존 dataSource와 scroll 위치로 이동시킴
  useEffect(() => {}, [])

  const getType = (menu) => {
    const type =
      menu === 'strategic'
        ? '전략적 관광컨설팅'
        : menu === 'tourism-promotion'
        ? '관광진흥컨설팅'
        : menu === 'oper-mgm-commecial'
        ? '운영관리 및 사업화'
        : menu === 'space-tourism-dev'
        ? '공간 및 관광개발사업'
        : '전략적 관광컨설팅'
    return type
  }

  const getDiscription = (menu) => {
    const discription =
      menu === 'strategic'
        ? '국책공모사업기본계획, 지역관광종합개발계획, 광역관광개발계획까지 <br/>지역의 지속가능한 관광 발전을 위한 거시적인 전략 로드맵 수립'
        : menu === 'tourism-promotion'
        ? '지역만의 고유한 정체성을 바탕으로 관광 콘텐츠 및 스토리 발굴은 물론 <br/>관광 브랜드 기획 및 관광상품 운영까지 지역관광 활성화를 위한 구체적인 방향 제시'
        : menu === 'oper-mgm-commecial'
        ? '관리운영계획, 사업타당성 분석 및 실행계획, 투자유치 자문 등 <br/>성공적인 사업 운영을 위한 실질적이고 분석적인 운영전략 제안'
        : menu === 'space-tourism-dev'
        ? '축적된 지식과 기술력을 바탕으로 관광(단)지, 리조트, 단위 관광시설, 휴양지구계획 등 <br/>효율적이고 합리적인 맞춤형 관광개발 계획 수립'
        : '국책공모사업기본계획, 지역관광종합개발계획, 광역관광개발계획까지 지역의 지속가능한 관광 발전을 위한 거시적인 전략 로드맵 수립'
    return discription
  }
  

  const fetchData = (paramType) => {
    axios
      .get(url, {
        params: {
          category: '주요 프로젝트',
          sub_category: paramType || type,
          start: startIdx,
          rows,
        },
      })
      .then(({ data }) => {
        const responseData = data.data
        responseData.forEach((d, i, all) => {
          d.no = all.length - i
          d.create_time = d.create_time
            .replace('T', ' ')
            .substr(0, 10)
            .replace(/-/g, '.')
          return d
        })
        setDataSource([...dataSource, ...data.data])
        setStartIdx(startIdx + rows)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const refresh = () => {
    setDataSource(() => [])
    setStartIdx(0)
  }

  const changeMenu = (menuName) => {
    refresh()
    setMenu(menuName)
  }

  const Card = ({ title, content, imgSrc, date, id, hit }) => (
    <section className={styles['card']}>
      <div className={styles['imgage']}>
        <img
          src={imgSrc || LogoImg}
          style={{ height: '227px', width: '100%' }}
        />
      </div>
      <div className={styles['content']}>
        <h2>{type}</h2>
        <h1
          className={styles['title']}
          dangerouslySetInnerHTML={{ __html: decode(title) }}
        />
        <section
          className={styles['discription']}
          dangerouslySetInnerHTML={{ __html: decode(content) }}
        />
        <section className={styles['date']}>
          {date}
          <span className={styles['hit']}>
            <img src={EyeImg} style={{ height: '25px' }} /> {hit}
          </span>
        </section>
      </div>
    </section>
  )

  return (
    <article className={styles['portfolio']}>
      <Header
        title="PORTFOLIO"
        discription="<span>다양한 분야의</span> <span>ONE STEP SOLUTION 제공</span>"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']} ref={targetEle}>
        <div className={styles['menus']}>
          <Link
            to="/portfolio/strategic"
            className={`${styles['menu']} ${
              menu === 'strategic' ? activeClassName : ''
            }`}
            onClick={() => changeMenu('strategic')}
          >
            <div>전략적 관광컨설팅</div>
          </Link>
          <Link
            to="/portfolio/tourism-promotion"
            className={`${styles['menu']} ${
              menu === 'tourism-promotion' ? activeClassName : ''
            }`}
            onClick={() => changeMenu('tourism-promotion')}
          >
            <div>
              <span>관광진흥</span>
              <span>컨설팅</span>
            </div>
          </Link>
          <Link
            to="/portfolio/oper-mgm-commecial"
            className={`${styles['menu']} ${
              menu === 'oper-mgm-commecial' ? activeClassName : ''
            }`}
            onClick={() => changeMenu('oper-mgm-commecial')}
          >
            <div>운영관리 및 사업화</div>
          </Link>
          <Link
            to="/portfolio/space-tourism-dev"
            className={`${styles['menu']} ${
              menu === 'space-tourism-dev' ? activeClassName : ''
            }`}
            onClick={() => changeMenu('space-tourism-dev')}
          >
            <div>공간 및 관광개발사업</div>
          </Link>
        </div>
        <h1>{type}</h1>
        <div className={styles['line']}></div>
        <section className={styles['discription']} dangerouslySetInnerHTML={{ __html: discription }}>
        </section>
        <div className={styles['container']}>
          <section className={styles['cards']}>
            {/* {loading || !dataSource ? (
              <FullscreenSpinner />
            ) : ( */}
              <>
                {!loading && dataSource.length < 1 ? (
                  <>데이터가 없습니다</>
                ) : (
                  <InfiniteScroll
                    dataLength={dataSource.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={true}
                    loader={
                      <div style={{ textAlign: 'center' }}>
                        <BeatLoader color={'#ed1c24'} />
                      </div>
                    }
                    refreshFunction={refresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                  >
                    {dataSource.map((d) => (
                      <Link
                        to={`/portfolio/detail/${d.id}`}
                        key={d.id}
                        state={{ category: menu, paramData: d }}
                      >
                        <Card
                          title={d.title}
                          imgSrc={d.image}
                          content={d.content}
                          date={d.create_time}
                          id={d.id}
                          hit={d.hit}
                        />
                      </Link>
                    ))}
                  </InfiniteScroll>
                )}
              </>
            {/* )} */}
          </section>
        </div>
      </section>
      <Footer />
    </article>
  )
}
export default Portfolio
