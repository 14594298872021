import React, { useEffect, useRef, useState } from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './InsightDetail.module.scss'
import './Notification.table.scss'
import BackgroundImg from 'static/images/sub-img-4@3x.png'
import { Button, Input, notification, Select, Table } from 'antd'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import FullscreenSpinner from 'components/FullscreenSpinner'
import { boardColumns } from './Notification'
import decode from 'unescape'

const { Option } = Select

const InsightDetail = () => {
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(Number.parseInt(useParams().id))
  const baseUrl = '/api/insight'
  const [url, setUrl] = useState(baseUrl)
  const navigate = useNavigate()
  const location = useLocation()
  const { state = {} } = location
  const [data, setData] = useState(state && state.paramData ? state.paramData : null)
  const [ids, setIds] = useState(state && state.ids || [])
  const targetEle = useRef()

  useEffect(() => {
    if ((!state || !state.paramData)) {
      fetchData()
    }
    if ((!state || !state.ids)) {
      fetchIds()
    }
    patchHit()
  }, [id])

  useEffect(() => {
    targetEle.current.scrollIntoView()
  }, [data])

  const fetchData = () => {
    setLoading(true)
    axios
      .get(`${url}/${id}`)
      .then(({ data }) => {
        setData(data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchIds = () => {
    if (ids.length < 1) {
      axios
        .get(`${url}/ids`)
        .then(({ data }) => {
          setIds(data ?? [])
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const patchHit = () => {
    axios
      .patch(`${url}/${id}`, {
        hit: true,
      })
      .then((res) => {
        console.log('조회수 증가')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const goTo = (idx) => {
    const targetIdIdx = ids.findIndex((d) => d === id)
    const maxIdx = ids.length
    const nextIdIdx = targetIdIdx + idx
    if (nextIdIdx < 0) {
      notification.info({
        message: '첫 번째 글입니다.',
        placement: 'bottom',
        duration: 2,
      })
    } else if (nextIdIdx >= maxIdx) {
      notification.info({
        message: '마지막 글입니다.',
        placement: 'bottom',
        duration: 2,
      })
    } else {
      const nextId = ids[nextIdIdx]
      setId(nextId)
      navigate(`/story/insight/${nextId}`)
    }
  }

  return (
    <article className={styles['insight-detail']}>
      <Header
        title="탐구생활"
        discription="이슈메이커들의 소소한 일상(회사) 이야기"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']} ref={targetEle}>
        <section className={styles['container']}>
          {/* {loading || !data ? (
            <FullscreenSpinner />
          ) : ( */}
            <article>
              <section>
                <div className={styles.header}>
                  <div className={styles.title}>{data.title}</div>
                  <div>
                    {data.create_time
                      .replace('T', ' ')
                      .substr(0, 10)
                      .replace(/-/g, '.')}
                  </div>
                </div>
                <div
                  className={styles.detail}
                  dangerouslySetInnerHTML={{ __html: decode(data.content) }}
                />
              </section>
              <section className={styles['content-footer']}>
                <div className={styles['left-buttons']}>
                  <div
                    className={styles['footer-button']}
                    onClick={() => goTo(-1)}
                  >
                    &lt;
                  </div>
                  <div
                    className={styles['footer-button']}
                    onClick={() => goTo(1)}
                  >
                    &gt;
                  </div>
                </div>
                <div className={styles['right-buttons']}>
                  <Link to={'/story/insight'}>
                    <div className={styles['footer-button']}>목록</div>
                  </Link>
                </div>
              </section>
            </article>
          {/* )} */}
        </section>
      </section>
      <Footer />
    </article>
  )
}

export default InsightDetail
