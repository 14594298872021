import React, { useRef, useState } from 'react'
import Footer from 'pages/components/Footer'
import Header from 'pages/components/Header'
import styles from './PortfolioDetail.module.scss'
import BackgroundImg from 'static/images/sub-img-3@3x.png'
import PortpolioDetailImg from 'static/images/portpolio-detail-sample.png'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'
import decode from 'unescape'
import { notification } from 'antd'

const PortfolioDetail = () => {
  const navigate = useNavigate()
  const baseUrl = '/api/board'
  const location = useLocation()
  const { state = {} } = location
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(state.paramData ?? {})
  const [id, setId] = useState(Number.parseInt(useParams().id))
  const [ids, setIds] = useState([])
  const targetEle = useRef()
  useEffect(() => {
    if (!state || !state.paramData) {
      fetchData()
    }
    patchHit()
  }, [id])

  useEffect(() => {
    targetEle.current.scrollIntoView()
  }, [data])

  const fetchData = (paramType) => {
    return axios
      .get(`${baseUrl}/${id}`)
      .then(({ data }) => {
        setData(data)
        fetchIds(data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchIds = (data) => {
    if (ids.length < 1) {
      axios
        .get(`${baseUrl}/ids`, {
          params: {
            category: data.category,
            sub_category: data.sub_category,
          },
        })
        .then(({ data }) => {
          setIds(data ?? [])
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const patchHit = () => {
    axios
      .patch(`${baseUrl}/${id}`, {
        hit: true,
      })
      .then((res) => {
        console.log('조회수 증가')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const goTo = (idx) => {
    const targetIdIdx = ids.findIndex((d) => d === id)
    const maxIdx = ids.length
    const nextIdIdx = targetIdIdx + idx
    if (nextIdIdx < 0) {
      notification.info({
        message: '첫 번째 글입니다.',
        placement: 'bottom',
        duration: 2,
      })
    } else if (nextIdIdx >= maxIdx) {
      notification.info({
        message: '마지막 글입니다.',
        placement: 'bottom',
        duration: 2,
      })
    } else {
      const nextId = ids[nextIdIdx]
      setId(nextId)
      navigate(`/portfolio/detail/${nextId}`, {
        state: { cateogory: state.category ?? 'strategic' },
      })
    }
  }

  return (
    <article className={styles['portfolio']}>
      <Header
        title="PORTFOLIO"
        discription="다양한 분야의  ONE STEP SOLUTION 제공"
        backgroundImg={BackgroundImg}
      />
      <section className={styles['content']} ref={targetEle}>
        <section className={styles['container']}>
          <h2 className={styles['sub-title']}>{data.sub_category}</h2>
          <h1>
            <span className={styles['title']}>{data.title}</span>
            <span className={styles['date']}>{data.create_time}</span>
          </h1>
          <img className={styles['main-image']} src={data.image} />
          <section
            className={styles['content-body']}
            dangerouslySetInnerHTML={{ __html: decode(data.content ?? '') }}
          />
          <section className={styles['content-footer']}>
            <div className={styles['left-buttons']}>
              <div className={styles['footer-button']} onClick={() => goTo(-1)}>
                &lt;
              </div>
              <div className={styles['footer-button']} onClick={() => goTo(1)}>
                &gt;
              </div>
            </div>
            <div className={styles['right-buttons']}>
              <Link to={`/portfolio/${state.category ?? 'strategic'}`}>
                <div className={styles['footer-button']}>목록</div>
              </Link>
            </div>
          </section>
        </section>
      </section>
      <Footer />
    </article>
  )
}
export default PortfolioDetail
