import { Breadcrumb, Button, Input, notification, Upload } from 'antd'
import AdminLayout from 'pages/components/admin/AdminLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './BoardWrite.module.scss'
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/react-editor'

import fontSize from 'tui-editor-plugin-font-size'
import 'tui-editor-plugin-font-size/dist/tui-editor-plugin-font-size.css'

import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import 'tui-color-picker/dist/tui-color-picker.css'
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'

export const InterviewWrite = () => {
  const { state } = useLocation()
  const {
    id = null,
    title: pTitle = null,
    interviewee: pInterviewee = null,
    position: pPosition = null,
    content: pContent = null,
    profile_image: pProfileImage = null,
    header_image: pHeaderImage = null,
  } = state ? state : {}

  const baseUrl = '/api/interview'
  const [title, setTitle] = useState(pTitle || '')
  const [interviewee, setInterviewee] = useState(pInterviewee || '')
  const [position, setPosition] = useState(pPosition || '')
  const [profileFileList, setProfileFileList] = useState([])
  const [headerFileList, setHeaderFileList] = useState([])
  const editorRef = useRef()
  const navigate = useNavigate()
  const [profileImgPath, setProfileImgPath] = useState(pProfileImage)
  const [headerImgPath, setHeaderImgPath] = useState(pHeaderImage)
  useEffect(() => {
    if (id && pContent) {
      editorRef.current?.getInstance().setHTML(pContent)
    }
  }, [])

  const getFormData = (content) => {
    const formData = new FormData()

    formData.append(
        'profileFile',
        profileFileList && profileFileList[0] ? profileFileList[0].originFileObj : null
    )
    formData.append(
        'headerFile',
        headerFileList && headerFileList[0] ? headerFileList[0].originFileObj : null
    )
    formData.append('profileImgPath', profileImgPath)
    formData.append('headerImgPath', headerImgPath)
    formData.append('title', title)
    formData.append('content', content)
    formData.append('interviewee', interviewee)
    formData.append('position', position)
    return formData
  }
  
  const modifyContent = () => {
    const content = editorRef.current?.getInstance().getHTML()
    if (!title || !content || content === '<p><br></p>' || !interviewee) {
      notification.error({
        message: '입력이 누락된 항목이 있습니다.',
        placement: 'bottom',
        duration: 2,
      })
      return
    }
    if ((!profileImgPath && profileFileList.length < 1) || (!headerImgPath && headerFileList.length < 1)) {
      notification.error({
        message: '첨부가 누락된 파일이 있습니다.',
        placement: 'bottom',
        duration: 2,
      })
      return
    }
    const formData = getFormData(content)

    if (confirm('수정 전 내용은 복원되지 않습니다. 수정하시겠습니까?')) {
            
        axios
        .patch(`${baseUrl}/${id}`, formData, {
            header: { 'content-type': 'multipart/form-data' },
        })
        .then((res) => {
            notification.info({
            message: '수정되었습니다.',
            placement: 'bottom',
            duration: 2,
            })
            navigate('/admin/interview')
        })
        .catch((e) => {
            notification.error({
            message: e.response?.data || e.message,
            placement: 'bottom',
            duration: 2,
            })
        })
    }
  }
  const deleteContent = () => {
    if (confirm('삭제하시겠습니까? 삭제된 내용은 복원되지 않습니다')) {
        axios
          .delete(`${baseUrl}/${id}`)
          .then((res) => {
            notification.info({
              message: '삭제되었습니다.',
              placement: 'bottom',
              duration: 2,
            })
            navigate('/admin/interview')
          })
          .catch((e) => {
            debugger
            notification.error({
              message: e.response?.data || e.message,
              placement: 'bottom',
              duration: 2,
            })
          })
      }
  }
  
  const save = () => {
    const content = editorRef.current?.getInstance().getHTML()
    if (!title || !content || content === '<p><br></p>' || !interviewee) {
      notification.error({
        message: '입력이 누락된 항목이 있습니다.',
        placement: 'bottom',
        duration: 2,
      })
      return
    }
    if ((!profileImgPath && profileFileList.length < 1) || (!headerImgPath && headerFileList.length < 1)) {
      notification.error({
        message: '첨부가 누락된 파일이 있습니다.',
        placement: 'bottom',
        duration: 2,
      })
      return
    }
    const formData = getFormData(content)

    axios
      .post(baseUrl, formData, {
        header: { 'content-type': 'multipart/form-data' },
      })
      .then((res) => {
        notification.info({
          message: '저장되었습니다.',
          placement: 'bottom',
          duration: 2,
        })
        navigate('/admin/interview')
      })
      .catch((e) => {
        notification.error({
          message: e.response?.data || e.message,
          placement: 'bottom',
          duration: 2,
        })
      })
  }

  return (
    <AdminLayout>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Consultant</Breadcrumb.Item>
        <Breadcrumb.Item>인터뷰</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? '수정' : '작성'}</Breadcrumb.Item>
      </Breadcrumb>
      <section className={styles['button-area']}>
        <Input
          addonBefore="제목"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          style={{ marginBottom: '10px' }}
        />
      </section>
      <section className={styles['button-area']}>
        <Input
          addonBefore="인터뷰이"
          onChange={(e) => setInterviewee(e.target.value)}
          value={interviewee}
          style={{ marginBottom: '10px' }}
        />
      </section>
      <section className={styles['button-area']}>
        <Input
          addonBefore="직급"
          onChange={(e) => setPosition(e.target.value)}
          value={position}
          style={{ marginBottom: '10px' }}
        />
      </section>

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <div>
          {profileImgPath ? (
            <>
              {profileImgPath}
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => setProfileImgPath('')}
              >
                이미지삭제
              </Button>
            </>
          ) : null}
        </div>
        <Upload
          onChange={({ fileList: newFileList }) => {
              setProfileFileList([newFileList.pop()])
              setProfileImgPath('')
          }
          }
          fileList={profileFileList}
        >
          <Button icon={<UploadOutlined />}>프로필이미지 Upload</Button>
        </Upload>
      </div>

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <div>
          {headerImgPath ? (
            <>
              {headerImgPath}
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => setHeaderImgPath('')}
              >
                이미지삭제
              </Button>
            </>
          ) : null}
        </div>
        <Upload
          onChange={({ fileList: newFileList }) =>
            setHeaderFileList([newFileList.pop()])
          }
          fileList={headerFileList}
        >
          <Button icon={<UploadOutlined />}>헤더이미지 Upload</Button>
        </Upload>
      </div>
      <Editor
        ref={editorRef}
        previewStyle="vertical"
        height="600px"
        initialEditType="wysiwyg"
        useCommandShortcut={true}
        plugins={[fontSize, colorSyntax]}
        toolbarItems={[
          // ['heading'],
          [],
          ['bold', 'strike', 'link'],
          // ['hr', 'table', 'link'],
          // ['ul', 'task', 'indent', 'outdent'],
        ]}
      />
      <section className={styles['button-area']}>
        {id ? (
          <>
            <Button type="primary" onClick={modifyContent}>
              수정
            </Button>
            <Button
              type="primary"
              onClick={deleteContent}
              style={{ marginLeft: '5px' }}
            >
              삭제
            </Button>
          </>
        ) : (
          <Button type="primary" onClick={save}>
            글쓰기
          </Button>
        )}
      </section>
    </AdminLayout>
  )
}
