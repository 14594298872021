import React, { useState } from 'react'
import { useEffect } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'
import styles from './OurNumbers.module.scss'

const Card = ({ number, measure, discription, className }) => {
  return (
    <section className={className}>
      <div>
        <span className={styles['number']}>{number}</span>
        <span className={styles['measure']}>{measure}</span>
      </div>
      <div className={styles['card-discription']}>{discription}</div>
    </section>
  )
}

const OurNumbers = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const delay = 1500
  const duration = 800
  const [count1, setCount1] = useState(0)
  const [count2, setCount2] = useState(0)
  const [count3, setCount3] = useState(0)
  const maxCount1 = 12
  const maxCount2 = 206
  const maxCount3 = 121

  useEffect(() => {
    if (window.innerHeight < 667) {
      onChange(true)
      setIsLoaded(true)
    }
  }, [])

  const countUp = (max, duration, handler) => {
    let count = 0
    const it = setInterval(() => {
      if (count > max) {
        clearInterval(it)
        return
      }
      handler(count)
      count++
    }, duration / max)
  }

  const onChange = (isVisible) => {
    if (isVisible && !isLoaded) {
      setTimeout(() => {
        countUp(maxCount1, duration, setCount1)
      }, delay)
      setTimeout(() => {
        countUp(maxCount2, duration, setCount2)
      }, delay)
      setTimeout(() => {
        countUp(maxCount3, duration, setCount3)
      }, delay)
    } else {
      setCount1(maxCount1)
      setCount2(maxCount2)
      setCount3(maxCount3)
    }
  }

  return (
    <section className={`container ${styles['our-numbers']}`}>
      <ReactVisibilitySensor
        onChange={(isVisible) => {
          if (isVisible) {
            onChange(isVisible)
            setIsLoaded(true)
          }
        }}
      >
        {({ isVisible }) => (
          <section
          // style={{ backgroundColor: isVisible ? 'red' : 'blue' }}
          >
            <h1 className={styles['top']}>
              <div
                className={`${styles['top-content']} ${
                  isVisible || isLoaded ? styles['rise-up'] : ''
                }`}
              >
                Our Numbers
              </div>
            </h1>
            <section
              className={`${styles['discription']} ${
                isVisible || isLoaded ? styles['fadein'] : ''
              }`}
            >
              <p>2012년 창립 이래 쉴틈없이 달려가고 있는 플랜이슈</p>
              <p>
                플랜이슈가 그동안 걸어오며 만든 숫자들입니다(2024년 8월 기준).
              </p>
            </section>
            <section className={styles['content']}>
              <Card
                className={`${styles['card']} ${styles['card1']} ${
                  isVisible || isLoaded ? styles['fadein'] : ''
                }`}
                number={count1}
                measure={'년'}
                discription={'플랜이슈가 걸어온 길'}
              />
              <Card
                className={`${styles['card']} ${styles['card2']} ${
                  isVisible || isLoaded ? styles['fadein'] : ''
                }`}
                number={count2}
                measure={'개'}
                discription={'플랜이슈가 진행한 프로젝트'}
              />
              <Card
                className={`${styles['card']} ${styles['card3']} ${
                  isVisible || isLoaded ? styles['fadein'] : ''
                }`}
                number={count3}
                measure={'개'}
                discription={'플랜이슈가 만난 지자체'}
              />
            </section>
          </section>
        )}
      </ReactVisibilitySensor>
    </section>
  )
}

export default OurNumbers
