import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import 'antd/dist/antd.variable.min.css'
import { ConfigProvider } from 'antd'

ConfigProvider.config({
  theme: {
    primaryColor: '#ed1c24',
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
