import React from 'react'
import styles from './Promotion.module.scss'
import pdf from 'static/files/2024 플랜이슈 회사소개서.pdf'

const Promotion = () => (
  <section className={`${styles['promotion']}`}>
    <div className={styles['content']}>
      <h1>
        플랜이슈와 함께 <br />
        성공적인 프로젝트를 만드세요.
      </h1>
      <div>
        <a href={pdf} target="_blank" rel="noreferrer">
          <button className={styles['download']}>
            <a>회사소개서 다운로드</a>
          </button>
        </a>
      </div>
    </div>
  </section>
)

export default Promotion
